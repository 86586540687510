import { Component, Inject, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobCompleteModel, JobModel } from '../models';
import { JobService } from '../services/job.service';
import { pushDummyState, popDummyState } from '../services/history-utility';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-job-complete',
  templateUrl: './job-complete.component.html',
  styleUrls: ['./job-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobCompleteComponent implements OnInit, OnDestroy {
  constructor(
    private navigator: NavigationService,
    private jobService: JobService,
    public dialogRef: MatDialogRef<JobCompleteComponent>,
    @Inject(MAT_DIALOG_DATA) public job: JobModel) {
  }

  ngOnInit(): void {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    popDummyState();
  }

  complete() {
    this.jobService.complete({
      jobId: this.job.id,
      chargeable: this.job.chargeable,
      sendDetailsToClient: this.job.sendDetailsToClient,
      sendMeterReading: this.job.sendMeterReading
    } as JobCompleteModel);
    this.dialogRef.close(this.job);
    this.navigator.gotoPendingJobs();
  }

  close(): void {
    this.dialogRef.close();
  }
}
