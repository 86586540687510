import { Injectable } from '@angular/core';

function _localStorage() {
  return window.localStorage;
}

export const LAST_SCAN_KEY = 'last_scanned_item';
export const LAST_EQUIPMENT_WORKED_ON = 'last_equipment';

@Injectable()
export class LocalStorageService {
  static getLastScannedItem() {
    return this.getItem(LAST_SCAN_KEY);
  }

  static getAndDeleteLastScannedItem() {
    const scanItem = this.getItem(LAST_SCAN_KEY);
    this.removeItem(LAST_SCAN_KEY);
    return scanItem;
  }

  static setLastScannedItem(value) {
    this.setItem(LAST_SCAN_KEY, value);
  }

  static getLastEquipment() {
    return this.getItem(LAST_EQUIPMENT_WORKED_ON);
  }

  static setLastEquipment(value) {
    this.setItem(LAST_EQUIPMENT_WORKED_ON, value);
  }

  static getItem(key): any {
    return JSON.parse(_localStorage().getItem(key));
  }

  static setItem(key, value) {
    const storedValue = this.getItem(key);
    if (storedValue) {
      this.removeItem(key);
    }
    _localStorage().setItem(key, JSON.stringify(value));
  }

  static removeItem(key) {
    _localStorage().removeItem(key);
  }

  static clear() {
    _localStorage().clear();
  }
}
