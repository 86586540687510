import {
  Injectable,
  ErrorHandler, InjectionToken
} from '@angular/core';
import { LOGGEDIN_USER_STORAGE } from '../shared/constants';
import { LocalStorageService } from './local-storage';
import { ErrorService } from '../api/error.service';
import { ErrorModel } from '../models/error';
import { Account } from '../models/account';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) {}

  handleError(err: Error): void {
    console.error(err);

    const currentUser: Account = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
    const error = {
      technicianId: currentUser.technicianId,
      userName: currentUser.userName,
      errorJson: err.stack ? JSON.stringify(err.stack) : err.message
    } as ErrorModel;
    this.errorService.create(error).subscribe((_response) => console.log('logged error'));
  }
}
