import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NfcRoutingModule } from './nfc-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { EquipmentScanComponent } from './equipment-scan.component';
import { PartScanComponent } from './part-scan.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  declarations: [
    EquipmentScanComponent,
    PartScanComponent
  ]
})
export class NfcModule { }
