import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VirtualScrollModule } from 'angular2-virtual-scroll';

import { JobListComponent } from './job-list/job-list.component';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { JobListService } from './api/job-list.service';
import { AppService } from './app.service';
import { JobDetailComponent } from './job-detail/job-detail.component';
import { VenueComponent } from './venue/venue.component';
import { SignatureComponent } from './signature/signature.component';
import { ActionBtnComponent } from './action-btn/action-btn.component';
import { EquipmentService } from './api/equipment.service';
import { CustomerService } from './api/customer.service';
import { EquipmentAddComponent } from './equipment-add/equipment-add.component';
import { DatePipe, CommonModule, CurrencyPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CameraCaptureComponent } from './camera-capture/camera-capture.component';
import { CardLgComponent } from './card-lg/card-lg.component';
import { CardMdComponent } from './card-md/card-md.component';
import { CardsHorizontalComponent } from './cards-horizontal/cards-horizontal.component';
import { CardsVerticalComponent } from './cards-vertical/cards-vertical.component';
import {
  DexieService,
  NotificationService,
} from './services';
import { JobReleaseComponent } from './job-release/job-release.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { JobViewComponent } from './job-view/job-view.component';
import { SwipeableListComponent } from './swipeable-list/swipeable-list.component';
import { SwipeableTileComponent } from './swipeable-tile/swipeable-tile.component';
import { ScanComponent } from './scan/scan.component';
import { JobCompleteComponent } from './job-complete/job-complete.component';
import { UnsavedDataComponent } from './unsaved-data/unsaved-data.component';
import { SharedModule } from './shared/share.module';
import { NumberSpinnerComponent } from './number-spinner/number-spinner.component';
import { InitialLoadComponent } from './initial-load/initial-load.component';
import { DataSyncGuard } from './guards/data-sync.guard';
import { JobEditComponent } from './job-edit/job-edit.component';
import { ActionSheetComponent } from './action-sheet/action-sheet.component';
import { AccountComponent } from './account/account.component';
import { CommentService } from './api/comment.service';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { RouterModule } from '@angular/router';
import { ResolutionAddComponent } from './resolution-add/resolution-add.component';
import { EquipmentPartListComponent } from './equipment-part-list/equipment-part-list.component';
import { EquipmentPartAddComponent } from './equipment-part-add/equipment-part-add.component';
import { JobService } from './services/job.service';
import { JobPollingService } from './services/JobsPollingService';
import { StaticDataService } from './services/static-data.service';
import { ItemSelectComponent } from './item-select/item-select.component';
import { HistoryBackForwarGuard } from './guards/history-back-forward.guard';
import { BusyModalComponent } from './busy/busy.component';

@NgModule({
  declarations: [
    ActionBtnComponent,
    ActionSheetComponent,
    CameraCaptureComponent,
    CardLgComponent,
    CardMdComponent,
    CardsHorizontalComponent,
    CardsVerticalComponent,
    EquipmentAddComponent,
    EquipmentPartListComponent,
    EquipmentPartAddComponent,
    JobCompleteComponent,
    JobDetailComponent,
    JobListComponent,
    JobReleaseComponent,
    JobViewComponent,
    JobEditComponent,
    BusyModalComponent,
    MenuBarComponent,
    ScanComponent,
    SignatureComponent,
    SwipeableListComponent,
    SwipeableTileComponent,
    VenueComponent,
    NumberSpinnerComponent,
    InitialLoadComponent,
    UnsavedDataComponent,
    AccountComponent,
    EquipmentListComponent,
    ResolutionAddComponent,
    ItemSelectComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VirtualScrollModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    SharedModule
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    HttpErrorHandler,
    MessageService,
    JobListService,
    CommentService,
    EquipmentService,
    CustomerService,
    DataSyncGuard,
    HistoryBackForwarGuard,
    DexieService,
    NotificationService,
    AppService,
    JobService,
    JobPollingService,
    StaticDataService,
    { provide: 'Flipping', useValue: window['Flipping'] },
    { provide: 'Hammer', useValue: window['Hammer'] }
  ],
  entryComponents: [
    ScanComponent,
    JobCompleteComponent,
    JobEditComponent,
    BusyModalComponent,
    NumberSpinnerComponent,
    UnsavedDataComponent,
    ActionSheetComponent,
    SignatureComponent,
    JobReleaseComponent,
    EquipmentAddComponent,
    ResolutionAddComponent,
    ItemSelectComponent,
    AccountComponent,
    EquipmentPartAddComponent
  ]
})
export class JobsModule { }
