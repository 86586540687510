<app-shell icon="arrow_back"
  titlePrefix="JOB"
  [title]="(jobViewModel$ | async)?.job?.id"
  [breadCrumbs]="(jobViewModel$ | async)?.breadCrumbs"
  (close)="navigate.next()">

  <app-job-detail [job]="(jobViewModel$ | async)?.job"
    (assign)="actionPerformed.next(assignJobAction)"
    (start)="actionPerformed.next(startJobAction)">
  </app-job-detail>

  <div *ngIf="(jobViewModel$ | async)?.job?.statusId === isInProgress" style="position: fixed; bottom: 0; width: 100%; text-align: center; z-index: 1000;">
    <div style="display: inline-block; width: 100%;" class="top-shadow">
      <button mat-raised-button color="primary" (click)="actionSheetClicked.next()" class="action-button"
        style="border-radius: 0;">
        <mat-icon>expand_less</mat-icon> JOB ACTIONS
      </button>
    </div>
  </div>
</app-shell>

