import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { CardModel } from '../models';
import { Subject, Subscription } from 'rxjs';
import { startWith, pairwise, map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-cards-vertical',
  templateUrl: './cards-vertical.component.html',
  styleUrls: ['./cards-vertical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsVerticalComponent implements AfterViewInit, OnDestroy {
  @Input() cardsList: CardModel[];
  @Input() selectedIds: number[] = [];
  @Input() deleteEnabled = false;
  @Input() multiSelect = false;
  @Output() drilldown = new EventEmitter<number>();
  @Output() select = new EventEmitter<number>();
  @Output() delete = new EventEmitter<number>();
  @Output() scrollDown = new EventEmitter();
  @Output() scrollUp = new EventEmitter();

  viewPortItems: CardModel[];
  lastScrollPosition: Subject<number> = new Subject<number>();
  scrollingDownSub: Subscription;

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.lastScrollPosition.next(event.srcElement.scrollTop);
  }

  ngAfterViewInit() {
    this.scrollingDownSub = this.lastScrollPosition.asObservable()
      .pipe(
        startWith(0),
        pairwise(),
        map(([a, b]) => b - a > 0),
        distinctUntilChanged()
      )
      .subscribe((down) => down ? this.scrollDown.emit() : this.scrollUp.emit());
  }

  ngOnDestroy() {
    this.scrollingDownSub.unsubscribe();
  }

  emitSelect(id) {
    if (!this.multiSelect) { return; }

    if (this.selectedIds.indexOf(id) > -1) {
      this.selectedIds = this.selectedIds.filter((select) => select !== id);
    } else {
      this.selectedIds.push(id);
    }
    this.select.emit(id);
  }

  emitDelete(id) {
    this.delete.emit(id);
  }

  emitDrilldown(id) {
    this.selectedIds = [id];
    this.drilldown.emit(id);
  }

  trackByFn(index, card) {
    return card.uid || card.id || index; // or item.id
  }

}
