import { EquipmentModel } from './equipment';
import { JobPartModel } from './job-part';

export interface CustomerEquipmentModel {
  id: number;
  uid: string;
  customerId: number;
  equipmentId: number;
  serialNumber: string;

  equipment: EquipmentModel;
  jobParts: JobPartModel[];
}

export interface CustomerEquipmentStaticModel {
  id: number;
  customerId: number;
  equipmentId: number;
  serialNumber: string;
}

export class CustomerEquipment implements CustomerEquipmentModel {
  equipment: EquipmentModel;
  jobParts: JobPartModel[];

  constructor(
    public id: number,
    public uid: string,
    public customerId: number,
    public equipmentId: number,
    public serialNumber: string) {
      Object.defineProperties(this, {
        equipment: { value: null, enumerable: false, writable: true },
        jobParts: { value: [], enumerable: false, writable: true }
      });
    }

}
