<app-shell
  icon="close"
  [breadCrumbs]="['Complete Job']"
  (close)="close()">

  <div style="padding: 15px;">
    <mat-list role="list" style="margin: 0 -15px;">
      <mat-list-item role="listitem">
        <mat-checkbox [(ngModel)]="job.chargeable">Job Chargeable</mat-checkbox>
      </mat-list-item>
      <mat-list-item role="listitem">
        <mat-checkbox [(ngModel)]="job.sendMeterReading">Send Meter Reading</mat-checkbox>
      </mat-list-item>
      <mat-list-item role="listitem">
        <mat-checkbox [(ngModel)]="job.sendDetailsToClient">Send Job Details to Client</mat-checkbox>
      </mat-list-item>
    </mat-list>
    <div class="actions">
      <button mat-raised-button (click)="complete()"  color="primary" class="action-button" cdkFocusInitial>
        <mat-icon class="custom-icon">playlist_add_check</mat-icon>Complete
      </button>
    </div>
  </div>

</app-shell>
