<app-shell
  icon="arrow_back"
  [breadCrumbs]="(jobViewModel$ | async)?.breadCrumbs"
  (close)="navigate.next()">

  <app-cards-vertical
    [cardsList]="equipmentList$ | async | toJobEquipmentCards"
    [deleteEnabled]="(readonly$ | async) === false"
    (drilldown)="gotoEquipment($event)"
    (delete)="onEquipmentDelete($event)" class="scrollable">
  </app-cards-vertical>

  <div *ngIf="(readonly$ | async) === false" style="position: fixed; bottom: 15px; left: 15px; right: 15px">
    <div style="color: #333; text-align: center; width: 100%;">
      <button mat-raised-button color="primary" class="action-button" (click)="addEquipmentClick.next()">
        <mat-icon class="custom-icon">playlist_add</mat-icon>Add Equipment
      </button>
    </div>
  </div>

</app-shell>

