import { NgModule } from '@angular/core';
import { JobListComponent } from './job-list/job-list.component';
import { JobViewComponent } from './job-view/job-view.component';
import { Routes, RouterModule } from '@angular/router';
import { EquipmentPartListComponent } from './equipment-part-list/equipment-part-list.component';
import { InitialLoadComponent } from './initial-load/initial-load.component';
import { DataSyncGuard } from './guards/data-sync.guard';
import { CanDeactivateGuard } from './guards/save.guard';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { HistoryBackForwarGuard } from './guards/history-back-forward.guard';

export const routes: Routes = [
  {
    path: 'initial',
    component: InitialLoadComponent,
  },
  {
    path: ':jobid',
    component: JobViewComponent,
    canActivate: [HistoryBackForwarGuard],
    data: { state: 'jobid', back: 'jobs' }
  },
  {
    path: ':jobid/equipments',
    component: EquipmentListComponent,
    canActivate: [HistoryBackForwarGuard],
    data: { state: 'jobid', back: 'jobs' }
  },
  {
    path: ':jobid/equipments/:equipmentid',
    component: EquipmentPartListComponent,
    canActivate: [HistoryBackForwarGuard],
    data: { state: 'equipmentid', back: 'equipments' }
  },
  {
    path: '',
    component: JobListComponent,
    canActivate: [DataSyncGuard, HistoryBackForwarGuard],
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class JobsRoutingModule { }
