<!-- <virtual-scroll #fullList [items]="cardsList" (vsUpdate)="viewPortItems = $event">
  <app-card-md *ngFor="let card of fullList.viewPortItems" [inputObject]="card" [selected]="card.id === selectedId"
    (click)="setSelected($event)" (assign)="setSelected($event)"></app-card-md>
</virtual-scroll>
 -->
 <app-card-md *ngFor="let card of cardsList; trackByFn"
  [inputObject]="card"
  [selected]="(selectedIds.indexOf(card.id) > -1)"
  [deleteEnabled]="deleteEnabled"
  (select)="emitSelect($event)"
  (drilldown)="emitDrilldown($event)"
  (delete)="emitDelete($event)">
</app-card-md>
