import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, take, switchMap, withLatestFrom } from 'rxjs/operators';
import { NavigationService } from '../services/navigation.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>;
}

@Injectable()
export class HistoryBackForwarGuard implements CanActivate {
  constructor(private navigation: NavigationService, private router: Router, private activatedRoute: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUrl = state.url;
    return this.navigation.navigationViaNativeBack$.pipe(
        withLatestFrom(this.navigation.previousUrl$, (navigatedViaBack, previousUrl) => ({ navigatedViaBack, previousUrl })),
        take(1),
        switchMap(({ navigatedViaBack, previousUrl }) => {
          if (navigatedViaBack === true && previousUrl === '/') {
            history.go(window.history.length + 1);
            return of(false);
          }

          previousUrl = previousUrl.split('?')[0];
          const urlSegments = previousUrl.split('/');

          if (urlSegments.length > 0) {
            urlSegments.pop();
          }

          if (navigatedViaBack === true) {
            this.router.navigate(urlSegments, { relativeTo: this.activatedRoute });
          }
          return navigatedViaBack === true ? of(false) : of(true);
        })
      );
  }
}
