<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class="fab-container" [style.bottom]="bottomMargin">
  <button mat-fab [disabled]="disabled" (click)="emitActionClick(actions[0].action)" *ngIf="actions.length === 1"
    class="fab-toggler" color="primary">
    <i class="material-icons">{{actions[0].icon}}</i> <!-- Animation here -->
  </button>
  <button mat-fab [disabled]="disabled" (click)="onToggleFab()" *ngIf="actions.length > 1"
    class="fab-toggler" color="primary">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i> <!-- Animation here -->
  </button>
  <div [@speedDialStagger]="buttons.length">
    <!-- and here -->
    <button *ngFor="let btn of buttons" mat-mini-fab color="primary" (click)="emitActionClick(btn.action)">
      <i class="material-icons">{{btn.icon}}</i>
    </button>
  </div>
</div>
