import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  Inject
} from '@angular/core';
import { AppService } from '../app.service';
import {
  AddEquipmentModel,
  CardModel,
  ScanItem,
  ScanType,
  CardTypeEnum,
  CustomerEquipment,
  JobModel,
  JobEquipment
} from '../models/index';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScanComponent } from '../scan/scan.component';
import { LocalStorageService } from '../services';
import { Observable, Subject } from 'rxjs';
import { NFCFactory } from '../models/nfc-factory';
import { takeUntil, combineLatest, map, take } from 'rxjs/operators';
import { StaticDataService } from '../services/static-data.service';
import { v4 as uuidv4 } from 'uuid';
import { pushDummyState, popDummyState } from '../services/history-utility';

export const PRIMARY_EQUIPMENT_TYPE = 1;

@Component({
  selector: 'app-equipment-add',
  templateUrl: './equipment-add.component.html',
  styleUrls: ['./equipment-add.component.scss']
})
export class EquipmentAddComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() customerId: number;
  @Input() idsAlreadyAdded: number[] = [];
  @ViewChild('fullList') fullList: ElementRef;
  @Output() addComplete = new EventEmitter<AddEquipmentModel>();

  nfcFactory: NFCFactory;
  nfcScannedData: Observable<ScanItem>;
  destroyed = new Subject();

  loadingEquipments = false;
  currentEquipmentTypeId: number = PRIMARY_EQUIPMENT_TYPE;
  currentEquipmentTypes: CardModel[] = [];
  equipments: CardModel[] = [];
  breadCrumbs: CardModel[] = [{ id: 1, header: 'Root' } as CardModel];
  selected: CardModel;

  constructor(
    public appService: AppService,
    public staticDataService: StaticDataService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EquipmentAddComponent>,
    @Inject(MAT_DIALOG_DATA) public job: JobModel) { }

  ngOnInit() {
    pushDummyState(this.dialogRef.id);

    this.nfcFactory = new NFCFactory();
    this.nfcScannedData = this.nfcFactory.data.asObservable();
    this.nfcScannedData.pipe(takeUntil(this.destroyed)).subscribe((v) => {
      if (v.type === ScanType.Equipment) {
        this.loadBySerialNumber(v.value);
      } else if (v.type === ScanType.CustomerEquipment) {
        // this.add(v.id, v.value);
      }
    });

    this.loadEquipments(this.currentEquipmentTypeId).subscribe((result) => this.currentEquipmentTypes = result);
  }

  ngOnChanges(changes) {
    if (changes.idsAlreadyAdded) {
      this.equipments = this.equipments
        .filter((equipment) => this.idsAlreadyAdded.indexOf(equipment.id) === -1);
    }
  }

  ngAfterViewInit() {
    const lastScannedItem: ScanItem = LocalStorageService.getAndDeleteLastScannedItem();
    const lastEquipment: any = LocalStorageService.getLastEquipment();
  }

  loadBySerialNumber(serialNumber: string) {
    this.staticDataService.getEquipmentBySerialNumber$(serialNumber).subscribe((result) => {
      this.loadEquipments(result.equipmentTypeId).subscribe((e) => {
        this.currentEquipmentTypes = e;
        this.cardClicked(result.id, result.serialNumber);
      });
    });
  }

  breadCrumbClicked(equipmentTypeId: number) {
    this.loadEquipments(equipmentTypeId).subscribe((result) => this.currentEquipmentTypes = result);
  }

  loadEquipments(equipmentTypeId: number) {
    const breadCrumbsTemp = this.breadCrumbs;
    while (breadCrumbsTemp.findIndex((b: CardModel) => b.id === equipmentTypeId) + 1 < breadCrumbsTemp.length) {
      this.breadCrumbs.pop();
    }

    return this.staticDataService.getEqupmentTypesByParentTypeId$(equipmentTypeId).pipe(
      map((values) => values.map((v) => CardModel.fromEquipmentTypeModel(v))),
      combineLatest(
        this.staticDataService.getEquipmentByTypeId$(equipmentTypeId, this.idsAlreadyAdded).pipe(
          map((values) => values.map((v) => CardModel.fromEquipmentModel(v)))
        ),
        (equipmentTypes, equipments) => ({
          equipmentTypes: equipmentTypes ? equipmentTypes : [],
          equipments: equipments ? equipments : []
        })),
      map((result) => result.equipmentTypes.concat(result.equipments)),
      take(1)
    );
  }

  ngOnDestroy() {
    this.destroyed.next();
    popDummyState();
  }

  nfcScan() {
    this.nfcFactory.scan();
  }

  consoleLog(data) {
    const logElement = document.getElementById('log');
    logElement.innerHTML += '\n' + data;
  }

  cardClicked(id: number, serialNumber: string = '') {
    const card = this.currentEquipmentTypes.find((c) => c.id === id);
    this.selected = card;
    if (card.type === CardTypeEnum.EquipmentType) {
      this.breadCrumbs.push(Object.assign({}, card));
      this.loadEquipments(card.id).subscribe((result) => this.currentEquipmentTypes = result);
    } else if (card.type === CardTypeEnum.Equipment) {
      this.openDialog(id, serialNumber);
    }
  }

  add(equipmentIdSelected: number, serialNumber: string) {
    this.staticDataService.getEquipmentById$(equipmentIdSelected).subscribe((equipment) => {
      this.addComplete.emit({ equipment, customerEquipmentSerialNumber: serialNumber } as AddEquipmentModel);

      const customerEquipment = new CustomerEquipment(0,
        uuidv4(), this.job.customerId, equipment.id, serialNumber);

      let jobEquipment = new JobEquipment(0,
        uuidv4(), this.job.id, null, customerEquipment.uid);

      jobEquipment = Object.assign({}, jobEquipment, { customerEquipment });

      this.dialogRef.close(jobEquipment);
    });
  }

  openDialog(equipmentIdSelected: number, barcode): void {
    const dialogRef = this.dialog.open(ScanComponent, {
      width: '350px',
      height: '280px',
      data: { barcode }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.add(equipmentIdSelected, result);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
