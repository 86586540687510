import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services';
import { EquipmentModel } from '../models/equipment';
import { CustomerEquipmentStaticModel } from '../models/customer-equipment';
import { EquipmentTypeModel } from '../models/equipment-type';
import { PartModel } from '../models/part';
import { ResolutionModel } from '../models';

@Injectable()
export class EquipmentService {
  baseApiEndpoint = 'api/staticdata';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('EquipmentService');
  }

  get (): Observable<EquipmentModel[]> {
    return this.http.get<EquipmentModel[]>(`${this.baseApiEndpoint}/equipment`)
      .pipe(
        catchError(this.handleError('getEquipments', []))
      );
  }

  getTypes (): Observable<EquipmentTypeModel[]> {
    return this.http.get<EquipmentTypeModel[]>(`${this.baseApiEndpoint}/equipmenttypes`)
      .pipe(
        catchError(this.handleError('getEquipmentTypes', []))
      );
  }

  getParts (): Observable<PartModel[]> {
    return this.http.get<PartModel[]>(`${this.baseApiEndpoint}/parts`)
      .pipe(
        catchError(this.handleError('getEquipmentParts', []))
      );
  }

  getResolutions (): Observable<ResolutionModel[]> {
    return this.http.get<ResolutionModel[]>(`${this.baseApiEndpoint}/resolutions`)
      .pipe(
        catchError(this.handleError('getEquipmentResolutions', []))
      );
  }

  getCustomerEquipment (): Observable<CustomerEquipmentStaticModel[]> {
    return this.http.get<CustomerEquipmentStaticModel[]>(`${this.baseApiEndpoint}/customerequipment`)
      .pipe(
        catchError(this.handleError('getCustomerEquipment', []))
      );
  }
}
