import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-busy',
  template: `<mat-spinner></mat-spinner>`,
  styles: [`

  `]
})
export class BusyModalComponent {
  constructor(
    public dialogRef: MatDialogRef<BusyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
}
