<div class="input-container"  mat-dialog-content>
  <button  mat-icon-button (click)="decrement()">
    <mat-icon>arrow_downward</mat-icon>
  </button>
  <input matInput type="number" [value]="value">
  <button  mat-icon-button (click)="increment()">
      <mat-icon>arrow_upward</mat-icon></button>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button mat-raised-button color="primary"  [mat-dialog-close]="value" cdkFocusInitial>{{actionButtonText}}</button>
</div>
