<div id="app" class="start" [attr.data-state]="expanded === true ? 'NAV1' : 'NAV2'" #app>
  <nav class="ui-nav" #uiNav>
    <div class="ui-nav-buttons">
      <div class="ui-icon">
        <ng-content select=".left-menus"></ng-content>
      </div>
      <div class="ui-icon">
        <ng-content select=".right-menus"></ng-content>
      </div>
    </div>
    <ul class="ui-nav-items">
      <li class="ui-nav-item" *ngFor="let title of titles; let i = index" [attr.data-key]="i+1" #uiNavItem>
        <div class="ui-nav-item-title">
          <span>{{title}}</span>
        </div>
      </li>
    </ul>
  </nav>
  <div class="ui-main">
    <div class="ui-articles" id="articles-container">
      <ng-content select="app-swipeable-tile"></ng-content>
    </div>
  </div>
  <div>
    <ng-content select="app-action-btn"></ng-content>
  </div>
</div>
