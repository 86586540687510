import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { JobModel, JobStatusType, CardModel } from '../models/index';
import { DatePipe } from '@angular/common';
import { NavigationService } from '../services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { JobEditComponent } from '../job-edit/job-edit.component';
import { ItemSelectComponent } from '../item-select/item-select.component';
import { JobService } from '../services/job.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobDetailComponent {
  @Input() job: JobModel;
  @Output() edit = new EventEmitter<number>();
  @Output() assign = new EventEmitter<number>();
  @Output() start = new EventEmitter<number>();
  @Output() viewResolutions = new EventEmitter<number>();

  constructor(
    private jobService: JobService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private datePipe: DatePipe) { }

  convertDate(value) {
    return this.datePipe.transform(value, 'MMM d, y, h:mm:ss a');
  }

  isInProgress() {
    return this.job?.statusId === JobStatusType.WIP;
  }

  isPending() {
    return this.job?.statusId === JobStatusType.Pending;
  }

  isAssigned() {
    return this.job && this.job.statusId === JobStatusType.Assigned;
  }

  editJob() {
    console.log('editing job');

    const dialogRef = this.dialog.open(JobEditComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: this.job
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  assignClick() {
    if (!this.job) {
      return;
    }
    this.assign.emit(this.job?.id);
  }

  startClick() {
    if (!this.job) {
      return;
    }
    this.start.emit(this.job?.id);
  }

  equipmentsClick() {
    if (!this.job) {
      return;
    }

    this.navigationService.goto(`jobs/${this.job.id}/equipments`);
  }

  resolutionsClick() {
    if (!this.job) {
      return;
    }
    const dialogRef = this.dialog.open(ItemSelectComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: {
        title: 'Resolutions',
        cards$: this.jobService.getCommentsByJobId$(this.job.id).pipe(
          map((res) => res.map((r) => CardModel.fromCommentModel(r)) )
        )
      }
    });

    dialogRef.afterClosed().subscribe((_) => console.log('resolutions closed'));
  }

  googleMapSearchLink(address: string) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  }

  emailLink(email: string) {
    return `mailto:${email}`;
  }

  phoneLink(phoneNumber: string) {
    return `tel:${phoneNumber}`;
  }
}
