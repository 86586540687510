<div>
  <app-top-bar (exit)="emitClose()" [exitIcon]="icon" [breadcrumbs]="breadCrumbs"></app-top-bar>

  <div *ngIf="title" style="color: #fff;background: #337ab7; padding: 0 15px; margin-top: 56px; width: 100%;" class="fixed-top">
    <h1 style="margin: 0 0 15px 0;">
      <span style="color: #56bbed;color: #98c8e0;">{{titlePrefix}}</span>{{title}}
    </h1>
  </div>

  <div id="wrapper" [style.margin-top]="title ? '106px' : '56px' ">
    <ng-content></ng-content>
  </div>
</div>
