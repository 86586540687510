<div mat-dialog-content>
  <p>Enter serial Number</p>
  <mat-form-field>
    <input matInput [formControl]="barcode">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()" class="action-button">Cancel</button>
  <button mat-raised-button color="primary" class="action-button" (click)="addSerial()" cdkFocusInitial>
    Add
</button>
</div>
