import { Component, OnChanges, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import imageCompression from 'browser-image-compression';

@Component({
  selector: 'app-camera-capture',
  templateUrl: './camera-capture.component.html',
  styleUrls: ['./camera-capture.component.scss']
})
export class CameraCaptureComponent implements AfterViewInit, OnChanges {
  @Input() photoDataUrl: string;
  @Input() disable: boolean;
  @Output() photoTaken = new EventEmitter<string>();
  @ViewChild('photoInput') photoInput: ElementRef;
  @ViewChild('photo') photo: ElementRef;
  @ViewChild('imageCanvas') imageCanvas: ElementRef;
  photoSrcUrl = '#';
  newPhotoTaken = false;

  get showPlaceHolder() {
    return this.photoSrcUrl === '#';
  }

  constructor() { }

  ngAfterViewInit() {
    this.photo.nativeElement.addEventListener('load', () => {
      const base64ImageString = this.getBase64Image(this.photo.nativeElement);
      if (base64ImageString !== this.photoDataUrl) {
        if (this.newPhotoTaken) {
          this.photoTaken.emit(base64ImageString);
        }
        this.newPhotoTaken = false;
      }
    });
  }

  ngOnChanges(changes) {
    if (changes.photoDataUrl && changes.photoDataUrl.currentValue) {
      this.photo.nativeElement.src = this.photoDataUrl;
      this.photoSrcUrl = this.photoDataUrl;
    }
  }

  takePhoto() {
    if (!this.disable) {
      this.photoInput.nativeElement.click();
    }
  }

  uploadPhoto(event) {
    this.newPhotoTaken = true;
    const files = Array.from(event.target.files);
    if (files && files.length) {
      const file = files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };

      imageCompression(file, options)
        .then(function (compressedFile) {
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          const selfieUrl = URL.createObjectURL(compressedFile);
          this.photo.nativeElement.src = selfieUrl;
          this.photoSrcUrl = selfieUrl;
        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        });
    }
}

getBase64Image(img) {
  const canvas = this.imageCanvas.nativeElement;
  // work out if we need to scale the image
  // we have an arbitrary max width of 600 px
  let scaleFactor = 1;
  if (img.naturalWidth > 600) {
    scaleFactor = 600 / img.naturalWidth;
    canvas.width = 600;
    canvas.height = img.naturalHeight * scaleFactor;
  } else {
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
  }
  const ctx = canvas.getContext('2d');
  ctx.scale(scaleFactor, scaleFactor);
  ctx.drawImage(img, 0, 0);

  const dataURL = canvas.toDataURL('image/jpeg', 0.5);
  return dataURL;
  // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

}
