import { Injectable } from "@angular/core";
@Injectable()
export class DateWrapper {
  createDateFromTime(time: number) {
    return new Date(time);
  }
  getCurrentTime() {
    const now = new Date();
    return now.getTime();
  }
}
