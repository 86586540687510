import { CardTypeEnum } from './card-type.enum';
import { EquipmentModel } from './equipment';
import { EquipmentTypeModel } from './equipment-type';
import { JobModel } from './job';
import { JobStatusTypeNames } from './job-status-type.enum';
import { JobEquipmentModel } from './job-equipment';
import { PartModel } from './part';
import { ResolutionModel } from './resolution';
import { JobPartModel } from './job-part';
import { CommentModel } from './comment';

export interface CardModel {
  id: number;
  uid: string;
  header: string;
  status: string;
  lines: string[];
  icon: string;
  category: string;
  type: CardTypeEnum;
}

export class CardModel {
  static fromEquipmentModel(model: EquipmentModel) {
    return {
      header: model.name,
      id: model.id,
      lines: [`${model.description}`, `${model.serialNumber}`],
      status: '',
      icon: 'settings',
      category: model.equipmentTypeId.toString(),
      type: CardTypeEnum.Equipment
    } as CardModel;
  }

  static fromEquipmentTypeModel(model: EquipmentTypeModel) {
    return {
      header: model.name,
      id: model.id,
      lines: [`${model.description}`, `${model.comment}`],
      status: '',
      icon: 'business',
      category: model.parentTypeId.toString(),
      type: CardTypeEnum.EquipmentType
    } as CardModel;
  }

  static fromJobModel(model: JobModel) {
    return {
      header: model.customer ? model.customer.name : 'no customer set',
      id: model.id,
      lines: [
        model.customer ? `${model.customer.address1}, ${model.customer.address2}` : 'no customer address set',
        // CardModel.toReadableFormat(model.processingDate)
      ],
      status: JobStatusTypeNames.get(model.statusId),
      icon: 'watch_later'
    } as CardModel;
  }

  static fromJobEquipmentModel(model: JobEquipmentModel) {
    const cardModel = {
      id: model.id,
      uid: model.uid,
      header: 'Unknown',
      icon: 'settings',
      lines: [],
      status: 'na'
    } as CardModel;

    if (model.customerEquipment && model.customerEquipment.equipment) {
      const equipment = model.customerEquipment.equipment;
      cardModel.header = equipment.name;
      cardModel.lines = [
        `${equipment.description}`,
        `${equipment.serialNumber} ${model.customerEquipment.serialNumber ? ' - ' + model.customerEquipment.serialNumber : ''}`
      ];
      cardModel.status = `${equipment.id}`;
    }

    return cardModel;
  }

  static fromJobPartModel(model: JobPartModel) {
    return {
      id: model.id,
      uid: model.uid,
      header: model.part?.name,
      lines: [`${model.part?.barcode}`, `quantity: ${model.quantity}`],
      status: model.id.toString(),
      icon: 'settings'
    } as CardModel;
  }

  static fromPartModel(model: PartModel) {
    return {
      id: model.id,
      header: model.name,
      lines: [`${model.barcode}`, `${model.price}`],
      status: model.id.toString(),
      icon: 'settings'
    } as CardModel;
  }

  static fromResolutionModel(model: ResolutionModel) {
    return {
      id: model.id,
      header: model.resolution,
      icon: 'event',
      status: model.barcode,
      lines: []
    } as CardModel;
  }

  static fromCommentModel(model: CommentModel) {
    return {
      id: model.id,
      header: model.comment,
      icon: 'event',
      status: `${model.jobId}`,
      lines: []
    } as CardModel;
  }

  private static toReadableFormat (dateString) {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${strTime}`;
  }
}
