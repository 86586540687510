import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import {
  JobEquipmentModel,
  EquipmentTypeModel
} from '../models';
import { DexieService } from './dexie.service';
import { take, map, catchError, switchMap, tap } from 'rxjs/operators';
import {
  getPartsByEquipmentTypeId,
  getPartById
} from './query-utilities';
import Dexie from 'dexie';

@Injectable()
export class StaticDataService {

  constructor(private db: DexieService) {}

  getPartsByEquipmentTypeId$(equipmentTypeId: number, omitIds: number[] = []) {
    const parts$ = (db: DexieService) => from(getPartsByEquipmentTypeId(db, equipmentTypeId, omitIds));

    return parts$(this.db);
  }

  getEqupmentTypesByParentTypeId$(id): Observable<EquipmentTypeModel[]> {
    const typesPromise =  this.db.equipmentTypes
      .where('parentTypeId').equals(id)
      .and((type) => type.name !== 'Root')
      .reverse()
      .toArray();

    return from(typesPromise)
      .pipe(take(1));
  }

  getPartById$(partId: number) {
    const part$ = (db: DexieService) => from(getPartById(db, partId));

    return part$( this.db );
  }

  getEquipmentById$(equipmentId: number) {
    return from(this.db.equipments.get(equipmentId));
  }

  getEquipmentByTypeId$(typeId: number, idsToExclude: number[] = []) {
    if (idsToExclude.length > 0) {
      return from(this.db.equipments
        .where('equipmentTypeId').equals(typeId)
        .and((p) => idsToExclude.indexOf(p.id) === -1).toArray());
    }

    return from(this.db.equipments
      .where('equipmentTypeId').equals(typeId).toArray());
  }

  getEquipmentBySerialNumber$(serialNumber: string) {
    return from(this.db.equipments.where('serialNumber').equals(serialNumber).first());
  }

  getPartByBarcode$(barcode: string) {
    return from(this.db.equipments.where('barcode').equals(barcode).first());
  }

  getPartsAvailable$(jobId: number, jobEquipment: JobEquipmentModel) {
    if (jobEquipment &&
      jobEquipment.customerEquipment &&
      jobEquipment.customerEquipment.equipment) {

      const equipment = jobEquipment.customerEquipment.equipment;
      const partIds = jobEquipment.customerEquipment.jobParts.map((jp) => jp.partId);

      return this.getPartsByEquipmentTypeId$(equipment.equipmentTypeId, partIds).pipe(
        map((parts) => ({
          jobId,
          jobEquipment,
          parts
        })));
    }

    return of({
      jobId,
      jobEquipment,
      parts: []
    });
  }

  getResolutionTemplates$() {
    return from(this.db.resolutions.toArray());
  }

  getCustomerById$(customerId: number) {
    return from( this.db.customers.get(customerId) );
  }

  searchCustomerByVenueNumberOrName$(likeValue: string) {
    const filter = likeValue.toLowerCase();
    return from(this.db.customers
        .where('venueNumber').startsWithIgnoreCase(filter)
        .or('name').startsWithIgnoreCase(filter)
        .toArray()
      );
  }

  isEqupmentsTableEmpty$() {
    return from(this.db.equipments.count()).pipe(
        map((count) => count === 0),
        catchError((e: Dexie.DexieError) => {
          console.log(e.message);
          if (e.message.indexOf('VersionError') > -1) {
            return this.resetApp$();
          }
          throw e;
        })
      );
  }

  addBulk(tableName: string, data) {
    return this.db.table(tableName).bulkPut(data);
  }

  resetApp$() {
    this.db.close();
    return from(this.db.delete()).pipe(
      tap((v) => console.log(v)),
      switchMap((v) => this.db.open()),
      catchError((e) => {
        return of(0);
      })
    );
  }

}
