import { Component, Input, Inject, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobService } from '../services/job.service';
import { JobModel } from '../models';
import { pushDummyState, popDummyState } from '../services/history-utility';
import { NavigationService } from '../services/navigation.service';


@Component({
  selector: 'app-job-release',
  templateUrl: './job-release.component.html',
  styleUrls: ['./job-release.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobReleaseComponent implements OnInit, OnDestroy {
  @Input() jobId: number;

  comment = new FormControl('', Validators.required);

  constructor(
    private navigator: NavigationService,
    private jobService: JobService,
    public dialogRef: MatDialogRef<JobReleaseComponent>,
    @Inject(MAT_DIALOG_DATA) public job: JobModel) {}

  ngOnInit(): void {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    popDummyState();
  }

  async release() {
    await this.jobService.release(this.job.id, this.comment.value);
    this.dialogRef.close(this.comment.value);
    this.navigator.gotoPendingJobs();
  }

  close() {
    this.dialogRef.close();
  }
}
