import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Observable, Subscription } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-initial-load',
  templateUrl: './initial-load.component.html',
  styleUrls: ['./initial-load.component.css']
})
export class InitialLoadComponent implements OnInit {
  tasks = [];
  activeTask: string;
  color = 'primary';
  mode = 'determinate';
  value = 0;
  diameter = 250;
  completedTasks = [];
  nextTask: Observable<string>;
  taskSubscription: Subscription;
  totalNumberOfTasks = 1;
  returnUrl: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private navigator: NavigationService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.appService.init();
    this.totalNumberOfTasks = this.appService.NumberOfBackgroundTasks;
    this.taskSubscription = this.appService.BackgroundTask.subscribe((task) => this.changeActiveTask(task));
  }

  changeActiveTask(task) {
    if (this.activeTask) {
      this.tasks.unshift(this.activeTask);
    }

    this.activeTask = task;
    if (task.indexOf('complete') > -1) {
      this.completedTasks.push(task);
    }
    this.value = Math.round(this.completedTasks.length * (100 / this.totalNumberOfTasks));

    if (this.value === 100) {
      this.taskSubscription.unsubscribe();
      setTimeout(() =>  this.router.navigate([this.returnUrl]), 0);
    }
  }

  goBack() {
    this.navigator.gotoPendingJobs();
  }
}
