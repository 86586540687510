import { Component, OnInit, OnChanges, Input, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { JobModel, CustomerMini, CustomerSearchResults, Job, JobStatusType } from '../models';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { debounceTime, filter, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobReleaseComponent } from '../job-release/job-release.component';
import { StaticDataService } from '../services/static-data.service';
import { JobService } from '../services/job.service';
import { assign, cloneDeep } from 'lodash';
import { AppSession, AppEventsService } from '../services';
import { popDummyState, pushDummyState } from '../services/history-utility';

@Component({
  selector: 'app-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() job: JobModel;

  jobForm = this.fb.group({
    customerId: [null, [Validators.required]],
    contactNumber: [''],
    contactName: [''],
    processingDate: [null],
    jobTypeId: [null],
    svr: [''],
    // chargeable: [''],
    // customerSign: [''],
    problem: ['', [Validators.required]],
    comment: [''],
    // statusId: [''],
    mileage: [null],
    // uid: [''],
    // timestamp: [''],
    // currentStatusId: [''],
    // notified: [''],
    // technicianId: ['']
  });
  customerAutoComplete = new FormControl(null, Validators.required);
  customer: CustomerMini;
  customers: Observable<CustomerMini[]>;
  loadingCustomers = false;
  latestCustomerSearchResults = { start: new Date(), customers: [] } as CustomerSearchResults;

  constructor(private jobService: JobService,
    private staticDataService: StaticDataService,
    private appSession: AppSession,
    private appEvents: AppEventsService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<JobReleaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JobModel
  ) {
    this.job = data;
  }

  get customerId() {
    return this.jobForm.get('customerId');
  }

  ngOnInit() {
    this.customers = this.customerAutoComplete.valueChanges.pipe(
      debounceTime(250),
      filter((c) => c !== ''),
      // filter((c) => c.length >= 2),
      distinctUntilChanged(),
      map((value) => typeof value === 'string' ? value : value.name),
      mergeMap((c) => this._filterCustomers(c))
    );

    this.updateForm();
    pushDummyState(this.dialogRef.id);
  }

  ngOnChanges(changes) {
    if (changes.job.currentValue) {
      this.updateForm();
    }
  }

  ngOnDestroy() {
    popDummyState();
  }

  updateCustomer(event: MatAutocompleteSelectedEvent) {
    this.customerId.setValue(event.option.value.id);
  }

  convertDate(value) {
    return this.datePipe.transform(value, 'MMM d, y, h:mm:ss a');
  }

  displayFn(customer?: CustomerMini): string | undefined {
    return customer ? customer.name : undefined;
  }

  toggleLoading() {
    this.loadingCustomers = !this.loadingCustomers;
  }

  scrollToView(el) {
    el.scrollIntoView();
  }

  save() {
    if (!navigator.onLine && !this.job.id) {
      this.appEvents.emitAlert('You must be online to add a new job');
      return;
    }

    let toSave = cloneDeep(this.job);
    if (!toSave.id) {
      toSave = Job.create();
      toSave.technicianId = this.appSession.technicianId;
      toSave.statusId = JobStatusType.WIP;
      delete toSave.id;
    }
    toSave = assign({}, toSave, this.jobForm.value);

    console.log( cloneDeep(toSave) );
    this.jobService.addJob(toSave);
    this.dialogRef.close(toSave);
  }

  close() {
    this.dialogRef.close();
  }

  private updateForm() {
    this.jobForm.patchValue(this.job, { emitEvent: false });

    if (this.job.customerId) {
      this.staticDataService.getCustomerById$(this.job.customerId)
        .pipe(
          map((customer) => ({
            id: customer.id,
            name: customer.name,
            address1: customer.address1,
            address2: customer.address2
          } as CustomerMini))
        ).subscribe((customer) => {
          this.customer = customer;
          this.customerAutoComplete.setValue(customer);
        });
    }
  }

  private _filterCustomers(value: string): Observable<CustomerMini[]> {
    this.toggleLoading();
    const start = new Date();

    return this.staticDataService.searchCustomerByVenueNumberOrName$(value).pipe(
      map((customers) => {
        return {
          start: start,
          customers: customers.map((c) => ({
            id: c.id,
            name: c.name,
            address1: c.address1,
            address2: c.address2
          } as CustomerMini))
        } as CustomerSearchResults;
      }),
      map((results) => {
        this.toggleLoading();
        if (this.latestCustomerSearchResults.start < results.start) {
          this.latestCustomerSearchResults = results;
          return results.customers;
        } else {
          return this.latestCustomerSearchResults.customers;
        }
      }));
  }
}
