import { InjectionToken, Provider } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { JobService } from '../services/job.service';
import { JobModel, JobEquipmentModel, JobStatusType } from '../models';
import { cloneDeep } from 'lodash';
import { validate as uuidValidate } from 'uuid';

// token to access a stream with the information you need
export const JOB_INFO = new InjectionToken<Observable<JobModel>>(
  'A stream with current organization information'
);

export const JOB_PROVIDERS: Provider[] = [
  {
    provide: JOB_INFO,
    deps: [ActivatedRoute, JobService],
    useFactory: organizationFactory,
  },
];

export function organizationFactory(
  route: ActivatedRoute,
  jobService: JobService
): Observable<{
  job: JobModel,
  jobEquipment: JobEquipmentModel,
  breadCrumbs: string[]
}> {
  const params = route.params;

  return params.pipe(
    switchMap((p) => {
      const job$ = p.jobid ? jobService.getJobById$(+p.jobid) : of(null);
      const breadCrumbs = route.snapshot.url.map((u) => toTitleCase(u.path));

      if (breadCrumbs.length > 0 && uuidValidate(breadCrumbs[breadCrumbs.length - 1]) === true) {
        breadCrumbs.splice(breadCrumbs.length - 1, 1, '');
      }

      return job$.pipe(
          map((job: JobModel) => {
            const currentJobEqupment = job && job.jobEquipments ? job.jobEquipments.find((je) => je.uid === p.equipmentid) : null;
            return {
              job,
              jobEquipment: currentJobEqupment ? cloneDeep(currentJobEqupment) : null,
              breadCrumbs: job ? [jobStatusDescription(job.statusId), ...breadCrumbs ] : []
            };
          }),
        )
      ;
    }),
  );
}

export function jobStatusDescription(statusId: number) {
  if (!statusId) {
    return '';
  }
  if (statusId === JobStatusType.Assigned ||
    statusId === JobStatusType.WIP) {
    return 'Assigned';
  }
  if (statusId === JobStatusType.Pending) {
    return 'Pending';
  }
  if (statusId === JobStatusType.Completed) {
    return 'Completed';
  }
}

export function toTitleCase(str) {
  return str.replace(
      /\w\S*/g,
      function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

