import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services';
import { CustomerModel } from '../models/customer';

@Injectable()
export class CustomerService {
  baseApiEndpoint = 'api/staticdata';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('CustomerService');
  }

  get (): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(`${this.baseApiEndpoint}/customers`)
      .pipe(
        catchError(this.handleError('getCustomers', []))
      );
  }
}
