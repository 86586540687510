import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartScanComponent } from './part-scan.component';
import { EquipmentScanComponent } from './equipment-scan.component';

export const routes: Routes = [
  { path: 'equipment/:workid', component: EquipmentScanComponent  },
  { path: 'part/:workid', component: PartScanComponent  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class NfcRoutingModule {}
