import { Component, Input } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActionTypeEnum } from '../models';

@Component({
  selector: 'app-action-sheet',
  templateUrl: './action-sheet.component.html',
  styleUrls: ['./action-sheet.component.scss']
})
export class ActionSheetComponent {
  @Input() disableSignature = false;
  @Input() disableComplete = false;

  constructor(private bottomSheetRef: MatBottomSheetRef<ActionSheetComponent>) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  addResolution(event: MouseEvent) {
    this.bottomSheetRef.dismiss(ActionTypeEnum.AddResolution);
    event.preventDefault();
  }

  releaseJob(event: MouseEvent) {
    this.bottomSheetRef.dismiss(ActionTypeEnum.ReleaseJob);
    event.preventDefault();
  }

  getSignature(event: MouseEvent) {
    this.bottomSheetRef.dismiss(ActionTypeEnum.GetSignature);
    event.preventDefault();
  }

  completeJob(event: MouseEvent) {
    this.bottomSheetRef.dismiss(ActionTypeEnum.CompleteJob);
    event.preventDefault();
  }
}
