import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from './api/authentication.service';
import { LocalStorageService, JwtInterceptor, WindowRefService, DexieService } from './services';
import { AppSession } from './services/app-session';
import { DateWrapper } from './services/date-wrapper';
import { NavigationService } from './services/navigation.service';
import { AppEventsService } from './services/app-events.service';
import { CanDeactivateGuard } from './guards/save.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { JobsModule } from './jobs.module';
import { LoginModule } from './login/login.module';
import { NfcModule } from './nfc-interface/nfc.module';
import { AppConfig } from './app.config';
import { RollbarErrorHandler, } from './services/rollbar-error-handler';
import { ErrorService } from './api/error.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatSnackBarModule,
    JobsModule,
    LoginModule,
    NfcModule
  ],
  providers: [
    DexieService,
    AuthenticationService,
    CanDeactivateGuard,
    LocalStorageService,
    WindowRefService,
    NavigationService,
    AppEventsService,
    AppSession,
    DateWrapper,
    AppConfig,
    ErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

