import { Component, HostListener, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { AppEventsService, DexieService, LocalStorageService } from './services';
import { NavigationService } from './services/navigation.service';
import { take, takeUntil, filter, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JobPollingService } from './services/JobsPollingService';
import { repopulateJobs } from './services/query-utilities';
import { JobService } from './services/job.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LOGGEDIN_USER_STORAGE } from './shared/constants';


function _caches(): any {
  // return the global native browser chaches object
  return caches;
}
function _navigator(): any {
  // return the global native browser navigator object
  return navigator;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'gmd-pwa';
  destroyed = new Subject();

  @HostListener('window:storage', ['$event'])
  onStorageEvent(ev: StorageEvent) {
    if (ev.key === 'currentUser' && ev.newValue === null) {
      this.navigator.gotoLogin();
    }
  }

  @HostListener('window:popstate ', ['$event'])
  onPopState(ev: PopStateEvent) {
    const state = history.state || window.history.state;
    if (state && state.modal) {
      const dialogRef = this.dialog.getDialogById(state.modalId) || { close: (): void => {} };
      dialogRef.close();
    }
  }

  get nativeCaches(): any {
    return _caches();
  }

  get nativeNavigator(): any {
    return _navigator();
  }

  constructor(private appEventsService: AppEventsService,
    private router: Router,
    private db: DexieService,
    private jobsPollingService: JobPollingService,
    private jobService: JobService,
    private navigator: NavigationService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
    ) {

      if (this.nativeNavigator) {
        // listen to events from service worker
        fromEvent<MessageEvent>(this.nativeNavigator.serviceWorker, 'message').pipe(
            takeUntil(this.destroyed)
          ).subscribe(async (e) => {
            if (e.data === 'onSync') {
              this.jobService.syncJobEquipments().subscribe((v) => {
                console.log(v);
              });

              this.jobService.syncJobEquipmentParts().subscribe((v) => {
                console.log(v);
              });
            }

            if (e.data === 'subscribe') {
              const user = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
              user.subscribedToNotifications = true;
              LocalStorageService.setItem(LOGGEDIN_USER_STORAGE, user);
            }

            if (e.data === 'unsubscribe') {
              const user = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
              user.subscribedToNotifications = false;
              LocalStorageService.setItem(LOGGEDIN_USER_STORAGE, user);
            }
          });
      }
  }

  ngOnInit() {
    this.appEventsService.isUserLoginExpired.pipe(
        takeUntil(this.destroyed)
      )
      .subscribe((expired) => {
        if (expired === true) {
          this.navigator.gotoLogin('expired=true');
        }
      });

      this.appEventsService.alerts.pipe(
        takeUntil(this.destroyed)
      ).subscribe((response) => {
        this.snackbar.open(response.alert, response.action, {
          duration: 3000,
        });
      });

      window.addEventListener('unhandledrejection', (e) => {
        console.log('Error occured');
        console.log(e);
        // this.db.close();
        // this.db.open();
      });

      this.router.events.pipe(
        // tap((e) => console.log(e)),
        filter(event => event instanceof NavigationStart),
        map((e: NavigationStart) => !!e.restoredState),
        takeUntil(this.destroyed)
      ).subscribe((navigateViaNativeBack) => {
        this.navigator.navigateViaNativeBack(navigateViaNativeBack);
      });

      this.router.events.pipe(
        // tap((e) => console.log(e)),
        filter(event => event instanceof NavigationEnd),
        map((e: NavigationEnd) => e.url),
        takeUntil(this.destroyed)
      ).subscribe((previousUrl) => {
        this.navigator.previousUrlNext(previousUrl);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }
}
