<app-shell icon="close" [breadCrumbs]="['Client Signature']" (close)="close()">
  <div class="sig-container" #sigContainer1>
    <div class="header">
      <button mat-icon-button (click)="clear()">
        <mat-icon>close</mat-icon> Clear
      </button>
    </div>
    <div fxLayout="row">
      <canvas #signaturepadCanvas id="signature-pad" class="signature-pad"></canvas>
    </div>
    <div fxLayout="row" class="footer">
      <span>Sign above this line</span>
    </div>
  </div>
</app-shell>
