import { Component, Input, EventEmitter, Output, OnChanges, Inject, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ItemSelectComponent } from '../item-select/item-select.component';
import { StaticDataService } from '../services/static-data.service';
import { CardModel, JobModel, JobEquipmentModel } from '../models';
import { map } from 'rxjs/operators';
import { JobService } from '../services/job.service';
import { AppSession } from '../services';
import { pushDummyState, popDummyState } from '../services/history-utility';

@Component({
  selector: 'app-resolution-add',
  templateUrl: './resolution-add.component.html',
  styleUrls: ['./resolution-add.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResolutionAddComponent implements OnChanges, OnInit, OnDestroy {
  @Input() currentResolution: string;
  @Input() disable: boolean;
  @Output() resolutionUpdated = new EventEmitter<string>();

  resolution = new FormControl('', Validators.required);
  jobEquipment: JobEquipmentModel;
  updateSubscription: Subscription;
  showTemplates = false;

  constructor(
    private appSession: AppSession,
    private jobService: JobService,
    private staticDataService: StaticDataService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ResolutionAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JobModel) {}


  ngOnInit(): void {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    popDummyState();
  }

  ngOnChanges(changes) {
    if (changes.currentResolution) {
      this.resolution.setValue(this.currentResolution, { emitEvent: false });
    }
    if (changes.disable && this.disable) {
      this.resolution.disable({ emitEvent: false });
    }
  }

  linkEquipment() {
    const dialogRef = this.dialog.open(ItemSelectComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: {
        title: 'Select Job Equipment',
        cards$: of(this.data.jobEquipments).pipe(
            map((jes) => jes.map((je) => CardModel.fromJobEquipmentModel(je)))
          ),
      }
    });

    dialogRef.afterClosed().subscribe((result: CardModel) => {
      console.log('The dialog was closed', result);
      if (result) {
        const selectedJobEquipment = this.data.jobEquipments.find((je) => je.id === result.id);
        this.jobEquipment = selectedJobEquipment;
        console.log(this.jobEquipment);
      }
    });
  }

  selectTemplate() {
    const dialogRef = this.dialog.open(ItemSelectComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: {
        title: 'Add Resolution',
        cards$: this.staticDataService.getResolutionTemplates$().pipe(
          map((res) => res.map((r) => CardModel.fromResolutionModel(r)) )
        )
      }
    });

    dialogRef.afterClosed().subscribe((result: CardModel) => {
      console.log('The dialog was closed', result);
      if (result) {
        this.resolution.setValue(result.header);
      }
    });
  }

  addResolution() {
    const serialNumber = this.jobEquipment ? this.jobEquipment.customerEquipment.serialNumber : 'JOB RESOLUTION - NO ASSOCIATED EQUIPMENT';

    this.jobService.addComment(
      this.resolution.value,
      this.appSession.technicianId,
      this.data.id,
      serialNumber).subscribe((v) => {
        console.log(v);
        this.dialogRef.close();
      });
  }

  close() {
    this.dialogRef.close();
  }
}
