import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService, WindowRefService } from '../services';
import { ScanType, ScanItem } from '../models';
import { PREVIOUS_URL_KEY } from '../services/navigation.service';

@Component({
  selector: 'app-nfc-part-scan',
  templateUrl: './nfc-interface.component.html',
  styleUrls: ['./nfc-interface.component.scss']
})
export class PartScanComponent implements OnInit {
  @ViewChild('goto', { static: true }) link;
  gotoLink: string;
  serialNumber: string;
  scanItem = {
    name: 'part-scan',
    type: ScanType.Part,
    value: ''
  } as ScanItem;
  scancomplete = false;

  constructor(private route: ActivatedRoute,
    private windowRef: WindowRefService) { }

  ngOnInit() {
    this.scanItem.value = this.route.snapshot.params.workid;
    LocalStorageService.setLastScannedItem(this.scanItem);
    this.gotoLink = LocalStorageService.getItem(PREVIOUS_URL_KEY);

    if (!this.gotoLink) {
      this.gotoLink = 'jobs';
    }

    setTimeout(() => {
      this.windowRef.send('scancomplete');
      this.scancomplete = true;
    }, 1000);
  }

  close() {
    this.windowRef.closeCurrentWindow();
  }
}
