import { Component, OnInit } from '@angular/core';
import { LocalStorageService, WindowRefService } from '../services';
import { Account, DataSyncFrequency } from '../models';
import { FormControl } from '@angular/forms';
import { AppSession } from '../services/app-session';
import { NavigationService } from '../services/navigation.service';
import { environment } from 'src/environments/environment.prod';
import { MatDialogRef } from '@angular/material/dialog';
import { LOGGEDIN_USER_STORAGE } from '../shared/constants';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  jobSyncFrequencies: any[] = [
    {
      name: 'Every 30 seconds',
      value: 30000,
    },
    {
      name: 'Every minute',
      value: 60000,
    },
    {
      name: 'Every half an hour',
      value: 1800000,
    },
    {
      name: 'Every hour',
      value: 36000000,
    },
  ];
  dataSyncFrequencies: any[] = [
    DataSyncFrequency.Daily,
    DataSyncFrequency.Weekly,
    DataSyncFrequency.Monthly
  ];
  jobsSyncFrequency = new FormControl('');
  dataSyncFrequency = new FormControl('');
  account: Account;
  subscribedToNotifications = false;

  constructor(private appSession: AppSession,
    private navigationService: NavigationService,
    private windowref: WindowRefService,
    private dialogRef: MatDialogRef<AccountComponent>) { }

  ngOnInit() {
    this.account = this.appSession.account;
    this.jobsSyncFrequency.setValue(this.appSession.jobsSyncFrequency);
    this.dataSyncFrequency.setValue(this.appSession.dataSyncFrequency);

    const user = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
    this.subscribedToNotifications = user && user.subscribedToNotifications;
  }

  updateDataSyncFrequency() {
    this.appSession.updateDataSyncFrequency(this.dataSyncFrequency.value);
  }

  updateJobsSyncFrequency() {
    this.appSession.updateJobsSyncFrequency(this.jobsSyncFrequency.value);
  }

  subscribeToNotifications() {
    this.subscribedToNotifications = !this.subscribedToNotifications;

    const currentUser = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
    if (!currentUser) {
      return;
    }

    const postData = {
      action: this.subscribedToNotifications === true ? 'subscribe' : 'unsubscribe',
      publicKey: environment.applicationServerPublicKey,
      token: currentUser.token
    };
    const navigator = this.windowref.nativeWindow.navigator;

    if (!('Notification' in window)) {
      alert('This browser does not support system notifications');
      // This is not how you would really do things if they aren't supported. :)
    } else if (Notification.permission === 'granted') {
      console.log('granted');

      navigator.serviceWorker.controller.postMessage(postData);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        console.log('asking permission');
        // If the user accepts, let's create a notification
        if (permission === 'granted') {

          navigator.serviceWorker.controller.postMessage(postData);
        }
      });
    }

  }

  goBack() {
    this.navigationService.gotoPendingJobs();
  }

  close() {
    this.dialogRef.close();
  }

}
