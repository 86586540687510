import { Component, OnInit, Inject, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypeEnum,
  JobModel,
  JobCompleteModel,
  JobStatusType
} from '../models';
import { JobCompleteComponent } from '../job-complete/job-complete.component';
import { NavigationService } from '../services/navigation.service';
import { ActionSheetComponent } from '../action-sheet/action-sheet.component';
import { componentTransition } from '../shared/animations';
import { SignatureComponent } from '../signature/signature.component';
import { JobReleaseComponent } from '../job-release/job-release.component';
import { ResolutionAddComponent } from '../resolution-add/resolution-add.component';
import { JobService } from '../services/job.service';
import { JOB_PROVIDERS, JOB_INFO } from '../services/job.provider';
import { ComponentType } from '@angular/cdk/portal';
import { AppSession } from '../services';
import { JobViewModel } from '../models/job-viewmodel';

@Component({
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss'],
  animations: [componentTransition],
  providers: [JOB_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobViewComponent implements OnInit, OnDestroy {
  navigate = new Subject();
  destroyed = new Subject();
  actionPerformed = new Subject<ActionTypeEnum>();
  actionSheetClicked = new Subject();
  newDataSent = new Subject<JobViewModel>();
  jobViewModel$: Observable<JobViewModel>;
  bottomSheetRef: MatBottomSheetRef<ActionSheetComponent>;
  isInProgress = JobStatusType.WIP;
  assignJobAction = ActionTypeEnum.AssignJob;
  startJobAction = ActionTypeEnum.StartJob;

  constructor(private appSession: AppSession,
    private navigator: NavigationService,
    private jobService: JobService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    @Inject(JOB_INFO) readonly data$: Observable<JobViewModel>
  ) {
    this.data$.pipe(takeUntil(this.destroyed)).subscribe((data) => this.newDataSent.next(data));
    this.jobViewModel$ = this.newDataSent.asObservable(); // do this so we do not recreate this.data$ observable
  }

  ngOnInit() {
    this.navigate.asObservable().pipe(
      takeUntil(this.destroyed),
      withLatestFrom(this.jobViewModel$, (_, data) => data?.job?.statusId),
    ).subscribe((statusId) => {
      this.exit(statusId);
    });

    this.actionSheetClicked.asObservable().pipe(
      withLatestFrom(this.jobViewModel$, (_, data) => data.job),
      takeUntil(this.destroyed)
    ).subscribe((job) => this.openActionsSheet(job));

    this.actionPerformed.asObservable().pipe(
      takeUntil(this.destroyed),
      withLatestFrom(this.jobViewModel$, (action, data) => ({ action, jobId: data?.job?.id }))
    ).subscribe(({ action, jobId }) => {
      if (action === ActionTypeEnum.AssignJob) {
        this.assignJob(jobId, this.appSession.technicianId);
      }
      if (action === ActionTypeEnum.StartJob) {
        this.startJob(jobId);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  exit(statusId: number) {
    if (statusId === JobStatusType.WIP) {
      this.navigator.gotoAssignedJobs();
    } else if (statusId === JobStatusType.Pending) {
      this.navigator.gotoPendingJobs();
    } else if (statusId === JobStatusType.Completed) {
      this.navigator.gotoCompleteJobs();
    } else {
      this.navigator.gotoAssignedJobs();
    }
  }

  actionClicked(action: ActionTypeEnum, job: JobModel) {
    switch (action) {
      case ActionTypeEnum.AddResolution: {
        this.openDialog<ResolutionAddComponent, string>(ResolutionAddComponent, job)
          .subscribe((dialogResult) => {
            console.log(dialogResult);
          });
        break;
      }
      case ActionTypeEnum.ReleaseJob: {
        this.openDialog<JobReleaseComponent, string>(JobReleaseComponent, job)
          .subscribe((dialogResult) => {
            console.log(dialogResult);
          });
        break;
      }
      case ActionTypeEnum.GetSignature: {
        this.openDialog<SignatureComponent, string>(SignatureComponent, job)
          .subscribe((dialogResult) => {
            console.log(dialogResult);
          });
        break;
      }
      case ActionTypeEnum.CompleteJob: {
        this.openDialog<JobCompleteComponent, JobCompleteModel>(JobCompleteComponent, job)
          .subscribe((dialogResult) => {
            console.log(dialogResult);
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  assignJob(jobId: number, technicianId: number) {
    this.jobService.assign(jobId, technicianId);
  }

  startJob(jobId: number) {
    this.jobService.start(jobId);
  }

  openActionsSheet(job: JobModel): void {
    if (job.statusId !== JobStatusType.WIP) {
      return;
    }

    this.bottomSheetRef = this.bottomSheet.open(ActionSheetComponent);

    this.bottomSheetRef.afterDismissed().subscribe((action) => {
      this.bottomSheetRef = null;
      this.actionClicked(action, job);
    });
  }

  private openDialog<T, T1>(component: ComponentType<T>, currentJob: JobModel) {
    const dialogRef = this.dialog.open(component, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: currentJob
    });

    return dialogRef.afterClosed();
  }
}
