import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent implements OnInit {
  @Input() breadcrumbs: string[] = [];
  @Input() exitIcon = 'arrow_back';
  @Output() exit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  exitClick() {
    this.exit.emit();
  }

}
