import { Component, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, take, withLatestFrom, switchMap, map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { PartModel, JobEquipmentModel, ScanItem, ScanType, JobStatusType } from '../models';
import { ScanComponent } from '../scan/scan.component';
import { NumberSpinnerComponent } from '../number-spinner/number-spinner.component';
import { NavigationService } from '../services/navigation.service';
import { FormControl } from '@angular/forms';
import { componentTransition } from '../shared/animations';
import { CanComponentDeactivate } from '../guards/save.guard';
import { NFCFactory } from '../models/nfc-factory';
import { EquipmentPartAddComponent } from '../equipment-part-add/equipment-part-add.component';
import { JobService } from '../services/job.service';
import { JOB_PROVIDERS, JOB_INFO } from '../services/job.provider';
import { StaticDataService } from '../services/static-data.service';
import { JobViewModel } from '../models/job-viewmodel';

@Component({
  selector: 'app-equipment-part-list',
  templateUrl: './equipment-part-list.component.html',
  styleUrls: ['./equipment-part-list.component.scss'],
  animations: [componentTransition],
  providers: [JOB_PROVIDERS]
})
export class EquipmentPartListComponent implements AfterViewInit, OnDestroy, CanDeactivate<CanComponentDeactivate> {
  serialNumber = new FormControl('');
  readonly$: Observable<boolean>;
  nfcFactory: NFCFactory;
  nfcScannedData: Observable<ScanItem>;
  destroyed = new Subject();
  addPartSubject = new Subject();
  deleted = new Subject<string>();
  newDataSent = new Subject<JobViewModel>();
  jobViewModel$: Observable<JobViewModel>;
  isInProgress = JobStatusType.WIP;

  constructor(
    private navigator: NavigationService,
    private jobService: JobService,
    private staticDataService: StaticDataService,
    private dialog: MatDialog,
    @Inject(JOB_INFO) readonly data$: Observable<JobViewModel>) {
      this.data$.pipe(takeUntil(this.destroyed)).subscribe((data) => this.newDataSent.next(data));
      this.jobViewModel$ = this.newDataSent.asObservable(); // do this so we do not recreate this.data$ observable
      this.readonly$ = this.jobViewModel$.pipe(map((jv) => jv?.job?.statusId !== JobStatusType.WIP));
  }

  ngAfterViewInit() {
    /* const lastScannedItem: ScanItem = LocalStorageService.getAndDeleteLastScannedItem();
    if (lastScannedItem && lastScannedItem.type === ScanType.Part) {
      setTimeout(() => {
        this.showPartsList = true;
        this.openDialog(lastScannedItem.value);
      }, 0);
    } */

    this.nfcFactory = new NFCFactory();
    this.nfcScannedData = this.nfcFactory.data.asObservable();
    this.nfcScannedData.pipe(takeUntil(this.destroyed)).subscribe((v) => {
      if (v.type === ScanType.Part) {
        this.loadByBarcode(v.value);
      }
    });

    this.addPartSubject.asObservable().pipe(
      withLatestFrom(this.jobViewModel$, (_, data) => data),
      switchMap((data) => this.staticDataService.getPartsAvailable$(data.job.id, data.jobEquipment).pipe(take(1)) ),
      takeUntil(this.destroyed)
    ).subscribe((data) => {
      this.openAddPartDialog(data);
    });

    this.deleted.asObservable().pipe(
      withLatestFrom(this.jobViewModel$, (jobPartId, data) => ({ jobPartId, data })),
    ).subscribe(({ jobPartId, data }) => {
      this.jobService.deleteJobEquipmentPart(data.job.id, null, jobPartId).subscribe((v) => {
        console.log(`part deleted ${v}`);
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  nfcScan() {
    this.nfcFactory.scan();
  }

  canDeactivate() {
    return true;
    // TODO: check if data has already been synced to server and inform user if offline
    // return this.isDirty ? this.dialog.open(UnsavedDataComponent).afterClosed() : of(true);
  }

  addPartClick() {
    this.addPartSubject.next();
  }

  openAddPartDialog(data: { jobId: number, jobEquipment: JobEquipmentModel, parts: PartModel[] }) {
    console.log('addPartClick');
    const dialogRef = this.dialog.open(EquipmentPartAddComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  deletePart(jobEqupmentPartId: string) {
    this.deleted.next(jobEqupmentPartId);
  }

  openQuantityEditor(jobPartId: string) {
    console.log(jobPartId);
    this.jobService.getJobPartById$(jobPartId).pipe(
      take(1),
    ).subscribe((part) => {

      const dialogRef = this.dialog.open(NumberSpinnerComponent, {
        width: '300px',
        data: { value: part.quantity, actionButtonText: 'Update' }
      });

      dialogRef.afterClosed().subscribe((result: number) => {
        if (result) {
          part.quantity = result;
          this.jobService.updateJobEquipmentPart(part).subscribe((v) => {
            console.log('Job Quantity Updated');
          });
        }
      });
    });
  }

  openDialog(barcode): void {
    const dialogRef = this.dialog.open(ScanComponent, {
      width: '300px',
      data: { barcode }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (barcode) {
        barcode = result;
        this.loadByBarcode(barcode);
      }
    });
  }

  loadByBarcode(barcode: string) {
    console.log(barcode);
    // this.appService.findPartByBarcode(barcode).pipe(
    //   map((mPart: PartModel) => ({
    //     partId: mPart.id,
    //     partName: mPart.name,
    //     quantity: 1,
    //     customerEquipmentId: this.customerEquipmentId
    //   } as JobPartModel)
    //   )).subscribe((partToAdd) => {
    //     console.log(partToAdd);
    //     this.addedParts = this.updater.updateEquipmentParts(this.jobEquipmentId, [partToAdd]);
    //     this.availableParts = this.availableParts.filter((p) => this.addedParts.findIndex((ap) => (p.id === ap.id)) === -1);
    //   });
  }

  goBackToEquipments() {
    this.data$.pipe(take(1)).subscribe((d) => {
      this.navigator.goto(`jobs/${d.job.id}/equipments`);
    });
  }

  updateMeterReading(imagebase64: string) {
    this.data$.pipe(take(1)).subscribe((data) => {
      this.jobService.addJobEquipmentMeterReading(data.job.id, data.jobEquipment.id, imagebase64).subscribe((_v) => {
        console.log('meter reading updated');
      });
    });
  }

  updateSerialNumber() {
    // this.updater.updateEquipmentSerialNumber(this.jobEquipmentId, this.serialNumber.value);
  }
}
