import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService, WindowRefService } from '../services';
import { ScanType, ScanItem } from '../models';
import { PREVIOUS_URL_KEY } from '../services/navigation.service';
/*
function sendTouchEvent(x, y, element, eventType) {
  const touchObj = new Touch({
    identifier: Date.now(),
    target: element,
    clientX: x,
    clientY: y,
    radiusX: 2.5,
    radiusY: 2.5,
    rotationAngle: 10,
    force: 0.5,
  });

  const touchEvent = new TouchEvent(eventType, {
    cancelable: true,
    bubbles: true,
    touches: [touchObj],
    targetTouches: [],
    changedTouches: [touchObj],
    shiftKey: true,
  });
  console.log(element);
  console.log('dispatching touch event', x, y);
  element.dispatchEvent(touchEvent);
} */

@Component({
  selector: 'app-nfc-equipment-scan',
  templateUrl: './nfc-interface.component.html',
  styleUrls: ['./nfc-interface.component.scss']
})
export class EquipmentScanComponent implements OnInit, AfterViewInit {
  @ViewChild('goto') link: ElementRef;
  gotoLink: string;
  serialNumber: string;
  scanItem = {
    name: 'equipment-scan',
    type: ScanType.Equipment,
    value: ''
  } as ScanItem;
  scancomplete = false;

  constructor(private route: ActivatedRoute,
    private windowRef: WindowRefService) { }

  ngOnInit() {
    this.scanItem.value = this.route.snapshot.params.workid;
    LocalStorageService.setLastScannedItem(this.scanItem);
    this.gotoLink = LocalStorageService.getItem(PREVIOUS_URL_KEY);
    const baseUrl = `${this.windowRef.nativeWindow.location.protocol}//${this.windowRef.nativeWindow.location.host}`;
    if (!this.gotoLink) {
      this.gotoLink = `${baseUrl}/jobs`;
    } else {
      this.gotoLink = `${baseUrl}/${this.gotoLink}`;
    }
    this.scancomplete = true;
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.windowRef.send('scancomplete');
      const nativeLink = this.link.nativeElement;
      /* sendTouchEvent(nativeLink.offsetLeft + 0.5, nativeLink.offsetTop + 0.5, nativeLink, 'touchstart');
      sendTouchEvent(nativeLink.offsetLeft + 0.5, nativeLink.offsetTop + 0.5, nativeLink, 'touchmove');
      sendTouchEvent(nativeLink.offsetLeft + 0.5, nativeLink.offsetTop + 0.5, nativeLink, 'touchend'); */
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ scanType: ScanType.Equipment });
      }
      this.link.nativeElement.click();
      setTimeout(() => {
        this.windowRef.nativeWindow.close();
      }, 0);
    }, 0);

  }

  close(e) {
    console.log(e);
    // this.windowRef.closeCurrentWindow();
  }
}
