import { DexieService } from './dexie.service';
import {
  JobEquipment,
  JobEquipmentModel,
  JobStatusType,
  PartModel,
  JobPartModel,
  CommentModel,
  JobModel,
  CustomerEquipmentModel,
} from '../models';
import { merge, clone, omit, cloneDeep, sortBy, orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export async function getJob(db: DexieService, jobId: number, includeChildren = true) {
  const job: JobModel = cloneDeep( await db.jobs.get(jobId) );

  if (includeChildren) {
    await loadJobNavigationProperties(db, job);
  }

  return job;
}

export async function getJobs(db: DexieService, statusTypes: JobStatusType[]) {
  const jobs: JobModel[] = await db.jobs.where('statusId').anyOf(statusTypes).toArray();

  await Promise.all(jobs.map((job) => loadCustomer(db, job)));

  return orderBy(jobs, ['updatedAt'], ['desc']);
}

export async function loadCustomer(db: DexieService, job: JobModel) {
  if (!job.customerId) {
    return;
  }

  job.customer = await db.customers.get(job.customerId);
}

export async function loadJobNavigationProperties(db: DexieService, job: JobModel) {
  if (!job) {
    return;
  }

  [job.customer, job.jobEquipments] = await Promise.all([
    getCustomer(db, job),
    getJobEquipments(db, job.id),
  ]);
}

export async function getCustomer(db: DexieService, job: JobModel) {
  if (!job || !job.customerId) {
    return null;
  }

  return await db.customers.get(job.customerId);
}

export async function getJobEquipments(db: DexieService, jobId: number): Promise<JobEquipmentModel[]> {
  const jobEquipments = await db.jobEquipments.where('jobId').equals(jobId).toArray();

  return await Promise.all(jobEquipments.map(item => loadJobEquipmentNavigationProperties(db, item)));
}

export async function loadJobEquipmentNavigationProperties(db: DexieService, jobEquiment: JobEquipment) {
  [
    jobEquiment.customerEquipment,
    jobEquiment.meterReadingPhoto
  ] = await Promise.all([
    getCustomerEquipment(db, jobEquiment.customerEquipmentUid),
    db.meterReadings.where('jobEquipmentId').equals(jobEquiment.id).first()
  ]);

  return jobEquiment;
}

export async function getCustomerEquipment(db: DexieService, customerEquipmentUid: string) {
  if (!customerEquipmentUid) {
    return null;
  }

  const customerEquipment = await db.customerEquipments.get(customerEquipmentUid);

  if (customerEquipment) {
    await loadCustomerEquipmentNavigationProperties(db, customerEquipment);
  }

  return customerEquipment;
}

export async function loadCustomerEquipmentNavigationProperties(db: DexieService, customerEquipment: CustomerEquipmentModel) {
  [
    customerEquipment.jobParts,
    customerEquipment.equipment
  ] = await Promise.all([
    getJobEquipmentParts(db, customerEquipment.uid, customerEquipment.id),
    getEquipment(db, customerEquipment.equipmentId),
  ]);

  if (customerEquipment.jobParts && customerEquipment.jobParts.length > 0) {
    await Promise.all(customerEquipment.jobParts.map(async (jp) => loadJobPartNavigationProperties(db, jp)));
  }
}

export async function loadJobPartNavigationProperties(db: DexieService, jobPart: JobPartModel) {
  if (!jobPart.partId) {
    return;
  }

  jobPart.part = await db.parts.get(jobPart.partId);
}

export function getJobEquipmentParts(db: DexieService, customerEquipmentUid: string, customerEquipmentId: number) {
  if (!customerEquipmentUid && !customerEquipmentId) {
    return [];
  }

  return db.jobParts
    .where('customerEquipmentUid').equals(customerEquipmentUid)
    .or('customerEquipmentId').equals(customerEquipmentId)
    .toArray();
}

export function getEquipment(db: DexieService, equipmentId: number) {
  if (!equipmentId) {
    return null;
  }

  return db.equipments.get(equipmentId);
}

export async function getJobPartById(db: DexieService, jobPartId: string) {
  if (!jobPartId) {
    return null;
  }

  return await db.jobParts.get(jobPartId);
}

export async function getPartById(db: DexieService, partId: number) {
  if (!partId) {
    return null;
  }

  return await db.parts.get(partId);
}

export function getJobEqupment(db: DexieService, id: number, uid: string) {
  return db.jobEquipments.where('id').equals(id).or('uid').equals(uid).first();
}

export function getCustomerEqupment(db: DexieService, id: number, uid: string) {
  return db.customerEquipments.where('id').equals(id).or('uid').equals(uid).first();
}

// Update Functions
export function updateJobEquipment(db: DexieService, jobEquipment: JobEquipmentModel) {
  jobEquipment = cloneDeep(jobEquipment) as JobEquipmentModel;
  const customerEquipment = cloneDeep(jobEquipment.customerEquipment);

  // remove this as they are stored in separate tables
  delete jobEquipment.customerEquipment;
  delete customerEquipment.equipment;
  delete customerEquipment.jobParts;

  if (!jobEquipment.uid) {
    jobEquipment.uid = uuidv4();
  }

  if (!customerEquipment.uid) {
    customerEquipment.uid = uuidv4();
  }

  jobEquipment.customerEquipmentUid = customerEquipment.uid;

  return [
    getJobEqupment(db, jobEquipment.id, jobEquipment.uid).then((je) => {
      if (je) {
        jobEquipment.uid = je.uid;
      }
      return db.jobEquipments.put(jobEquipment);
    }),
    db.customerEquipments.put(customerEquipment)
  ];
}

export function updateJobPart(db: DexieService, job: JobModel, jobPart: JobPartModel) {
  const jobEquipment = job.jobEquipments.find((je) => je.customerEquipmentId === jobPart.customerEquipmentId);
  const customerEquipmentUid = jobEquipment ? jobEquipment.customerEquipment.uid : null;

  jobPart = cloneDeep(jobPart);
  jobPart.uid = jobPart.uid || uuidv4();
  jobPart.customerEquipmentUid = customerEquipmentUid;

  return db.jobParts.put(jobPart);
}

export function updateComment(db: DexieService, comment: CommentModel) {
  return db.comments.put(comment);
}

// StaticData Functions
export async function getPartsByEquipmentTypeId(db: DexieService, equipmentTypeId: number, omitIds: number[] = []): Promise<PartModel[]> {
  if (omitIds.length > 0) {
    return db.parts.where('equipmentTypeId').equals(equipmentTypeId)
      .and((p) => omitIds.indexOf(p.id) === -1).toArray();
  }

  return db.parts.where('equipmentTypeId').equals(equipmentTypeId).toArray();
}


// bulk functions
export async function repopulateJobs(db: DexieService, jobs: JobModel[], clearOld = true) {
  if (jobs && jobs.length === 0) {
    return;
  }
  // const job106471 = jobs.find((j) => j.id === 106471);
  // console.log(job106471);

  return db.transaction('rw',
    db.jobs,
    db.jobEquipments,
    db.customerEquipments,
    db.jobParts,
    db.comments,
    async () => {

      if (clearOld) {
        db.jobs.clear();
        db.jobParts.clear();
        db.jobEquipments.clear();
        db.customerEquipments.clear();
        // db.meterReadingPhotos.clear();
      }

      jobs.forEach(createAsyncUpdateJobFunction(db));
    });
}

export async function repopulatePendingJobs(db: DexieService, jobs: JobModel[]) {
  if (jobs && jobs.length === 0) {
    return;
  }

  return db.transaction('rw',
    db.jobs,
    db.jobEquipments,
    db.customerEquipments,
    db.jobParts,
    db.comments,
    async () => {
      jobs.forEach(createAsyncDeleteJobFunction(db));
      console.log('pending jobs cleared');
      jobs.forEach(createAsyncUpdateJobFunction(db));
    });
}

export const createAsyncUpdateJobFunction = (db: DexieService) => async (job: JobModel) => updateJob(db, job);
export const createAsyncDeleteJobFunction = (db: DexieService) => async (job: JobModel) => deleteJob(db, job.id);

export async function updateJob (db: DexieService, job: JobModel) {
  // Add or update contact. If add, record contact.id.
  const jobClone = omit(job, ['customer', 'jobEquipments', 'jobParts', 'jobStatuses', 'comments', 'customerEquipments']) as JobModel;
  const jobId = await db.jobs.put(jobClone);

  if (job.statusId === JobStatusType.WIP) {
    console.log(job);
  }

  const jobEquipmentsUpdatePromises = job.jobEquipments ? job.jobEquipments
    .map(jobEquipment => updateJobEquipment(db, jobEquipment))
    .reduce((a, b) => a.concat(b), []) :
    [];

  // await Promise.all([
  //   db.customerEquipments.where('jobId').equals(job.id) // references us
  //     .delete(),
  // ]);

  const jobEquipmentIds = await Promise.all([
    ...jobEquipmentsUpdatePromises
  ]);
  const jobPartIds = await Promise.all([
    ... (job.jobParts ? job.jobParts.map(jobPart => updateJobPart(db, job, jobPart)) : [])
  ]);
  const commentIds = await Promise.all([
    ...(job.comments ? job.comments.map(comment => updateComment(db, comment)) : []),
  ]);

  // await Promise.all([
  //   db.jobEquipments.where('jobId').equals(job.id) // references us
  //     .and(jobEquiment => jobEquipmentIds.indexOf(jobEquiment.uid) === -1) // Not anymore in our array
  //     .delete(),

  //   db.jobParts.where('jobId').equals(job.id) // references us
  //     .and(jobPart => jobPartIds.indexOf(jobPart.id) === -1) // Not anymore in our array
  //     .delete(),

  //   db.comments.where('jobId').equals(job.id) // references us
  //     .and(comment => commentIds.indexOf(comment.id) === -1) // Not anymore in our array
  //     .delete(),
  // ]);
}


export async function deleteJob (db: DexieService, jobId: number) {
  if (!jobId) {
    return;
  }

  const jobEquipments = await db.jobEquipments.where('jobId').equals(jobId).toArray();
  console.log(jobEquipments);
  await Promise.all( jobEquipments.map((je) => removeJobEquipment(db, je)) );
  await db.jobs.delete(jobId);
}

export async function removeJobEquipment(db: DexieService, jobEquipment: JobEquipmentModel) {
  if (!jobEquipment) {
    return;
  }

  if (!jobEquipment.customerEquipmentUid) {
    await db.jobParts.where('customerEquipmentUid').equals(jobEquipment.customerEquipmentUid).delete();
    await db.customerEquipments.delete(jobEquipment.customerEquipmentUid);
  }

  if (jobEquipment.uid) {
    return;
  }

  await db.jobEquipments.delete(jobEquipment.uid);
}

export async function syncJobs(type: string, localJobs: JobModel[], serverJobs: JobModel[]): Promise<any> {

  // this has been rewritten this to use the same logic as the old app
  // make a list of all jobs in the database, this is the delete list
  // for each new downloaded server job,
  //   if the job is already in the database
  //     take it off the delete list
  //   else
  //     add it to the database
  // then remove all jobs from the db that are still on the delete list

  console.log(type + ' jobs in the database: ');
  console.log(localJobs);
  let promisesList = [];
  const deleteList = new Set<number>();
  localJobs.forEach((j) => deleteList.add(j.id));

  promisesList = serverJobs.map(j => {
    return this.jobsRepo.findById(j.id).then(dbJob => {
      if (dbJob !== undefined && dbJob.id === j.id) {
        console.log('removing existing ' + type + ' job from the delete list:' + dbJob.id);
        deleteList.delete(j.id);
        if (dbJob.statusId !== j.statusId && dbJob.statusId !== JobStatusType.WIP) {
          console.log('status has been changed on the client of:' + j.id + ' from ' + dbJob.statusId + ' to ' + j.statusId);
          // this is tricky and we may need a steer from GMD on what to do in this situation.
          // Do we use just the updated status and keep existing local data or use all data from
          // the server and potentially loose local edits?
          const modifiedJob = merge({}, dbJob, { id: dbJob.id, statusId: j.statusId });
          return this.jobsRepo.update(dbJob.id, modifiedJob);
        }
      } else {
        console.log('adding new ' + type + ' job to the database:' + j.id);
        return this.jobsRepo.add(j);
      }
    });
  });

  return Promise.all(promisesList).then(() => {
    console.log(type + ' deleteList: ');
    console.log(deleteList);
    deleteList.forEach(id => {
      console.log('deleting: ' + id + ' (' + type + ')');
      this.jobsRepo.remove(id);
    });
  });
}
