import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AppService } from '../app.service';
import { CustomerModel } from '../models/customer';

@Component({
  selector: 'app-venue',
  templateUrl: './venue.component.html',
  styleUrls: ['./venue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VenueComponent {
  @Input() customer: CustomerModel;

  constructor() {
  }
}
