import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
  // animations: [
  //   trigger('showHide', [
  //     // ...
  //     state('hide', style({
  //       position: 'fixed',
  //       top: 0,
  //       left: 0,
  //       width: '100%',
  //       height: '100%',
  //       'pointer-events': 'none',
  //       opacity: 0,
  //       'will-change': 'transform, opacity',
  //       '-webkit-transform': 'scale(1.15)',
  //       'transform': 'scale(1.15)',
  //       '-webkit-transition': `
  //         -webkit-transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
  //         opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946)`,
  //       transition: `
  //         transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
  //         opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946)`
  //     })),
  //     state('show', style({
  //       'z-index': 1000,
  //       position: 'fixed',
  //       top: 0,
  //       left: 0,
  //       width: '100%',
  //       height: '100%',
  //       'will-change': 'transform, opacity',
  //       'pointer-events': 'auto',
  //       opacity: 1,
  //       '-webkit-transform': 'scale(1)',
  //       transform: 'scale(1)',
  //       '-webkit-transition': `
  //         -webkit-transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
  //         opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)`,
  //       transition: `
  //         transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946),
  //         opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)`
  //     })),
  //    /*  transition('open => closed', [
  //       animate('1s')
  //     ]),
  //     transition('closed => open', [
  //       animate('0.5s')
  //     ]), */
  //   ]),
  // ]
})
export class ShellComponent {
  @Input() titlePrefix: string;
  @Input() title: string;
  @Input() breadCrumbs: string[] = [];
  @Input() icon = 'close';
  @Input() open = false;
  @Output() close = new EventEmitter<boolean>();

  emitClose() {
    this.close.emit(true);
  }

}
