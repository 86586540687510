import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routes as jobRoutes } from './jobs-routing.module';
import { routes as nfcRoutes } from './nfc-interface/nfc-routing.module';
import { routes as loginRoutes } from './login/login-routing.module';

const appRoutes: Routes = [
  {
    path: 'jobs',
    children: jobRoutes
  },
  {
    path: 'login',
    children: loginRoutes
  },
  {
    path: 'nfc',
    children: nfcRoutes
  },
  {
    path: '',
    children: jobRoutes,
  },
  // { path: '**', redirectTo: 'jobs' } // TODO: create a page not found component
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
  ]
})
export class AppRoutingModule { }
