import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../app.service';
import { map } from 'rxjs/operators';
import { StaticDataService } from '../services/static-data.service';

@Injectable()
export class DataSyncGuard implements CanActivate {

  constructor(private router: Router, private staticDataService: StaticDataService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.staticDataService.isEqupmentsTableEmpty$().pipe(
      map((empty) => {
        if (empty) {
          this.router.navigate(['/jobs/initial'], { queryParams: { returnUrl: state.url } });
          return false;
        }

        return true;
      })
    );
  }
}
