import { tap } from 'rxjs/operators';
import { AppEventsService } from '../services';

export const tapAlerts = (item: string, appEvents: AppEventsService) => tap((response: any) => {
  if (!response) {
    return;
  }

  if (!navigator.onLine) {
    appEvents.emitAlert(`You are offline, ${item} will be synced when you are back online`);
  } else {
    appEvents.emitAlert(`${item} added successfully`);
  }
});

export const tapDeleteAlerts = (item: string, appEvents: AppEventsService) => tap((response: any) => {
  if (!response) {
    return;
  }

  if (navigator.onLine) {
    appEvents.emitAlert(`${item} deleted successfully`);
  } else {
    appEvents.emitAlert(`You are offline, deleted ${item} will be synced when you are back online`);
  }
});
