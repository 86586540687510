import { AppSettings, Account, DataSyncFrequency } from '../models';
import { LocalStorageService } from './local-storage';
import { Subject } from 'rxjs';
import { DateWrapper } from './date-wrapper';
import { Injectable } from '@angular/core';
import { LOGGEDIN_USER_STORAGE } from '../shared/constants';

export const MS_IN_DAY = 24 * 60 * 60 * 1000;
export const MS_IN_WEEK = 7 * 24 * 60 * 60 * 1000;
export const MS_IN_MONTH = 4 * 7 * 24 * 60 * 60 * 1000; // calculating to 28 days only

@Injectable()
export class AppSession {
  _updated: Subject<boolean>;

  constructor(private dateWrapper: DateWrapper) {
    this._updated = new Subject();
  }

  get updated() {
    return this._updated.asObservable();
  }

  get account(): Account {
    return this.technician;
  }

  get technicianId() {
    const technician = this.technician;
    return technician.technicianId ? technician.technicianId : undefined;
  }

  get token() {
    const technician = this.technician;
    return technician.token ? technician.token : undefined;
  }

  get dataSyncFrequency() {
    const appSettings = this.appSettings;
    return appSettings.dataSyncFrequency ? appSettings.dataSyncFrequency : MS_IN_DAY;
  }

  get jobsSyncFrequency() {
    const appSettings = this.appSettings;
    return appSettings.jobsSyncFrequency ? appSettings.jobsSyncFrequency : 1800000;
  }

  appSettingsExists() {
    return this.appSettings ? true : false;
  }

  applyDefaultSettings() {
    const defaultSettings = {
      dataSyncFrequency: DataSyncFrequency.Weekly,
      jobsSyncFrequency: 1800000 // every half an hour,
    } as AppSettings;
    this.applyAppSettings(defaultSettings);
  }

  isDataOutOfSync() {
    const appSettings = this.appSettings;
    const diff = this.dateWrapper.getCurrentTime() - appSettings.lastDataSyncDate;

    if (appSettings && appSettings.dataSyncFrequency && appSettings.lastDataSyncDate) {
      if (appSettings.dataSyncFrequency === DataSyncFrequency.Daily) {
        return (diff) >= MS_IN_DAY;
      }
      if (appSettings.dataSyncFrequency === DataSyncFrequency.Weekly) {
        return (diff) >= MS_IN_WEEK;
      }
      if (appSettings.dataSyncFrequency === DataSyncFrequency.Monthly) {
        return (diff) >= MS_IN_MONTH;
      }
    }

    return true;
  }

  lastSyncDate() {
    return this.dateWrapper.createDateFromTime(this.appSettings.lastDataSyncDate);
  }

  updateLastDataSyncDate() {
    const appSettings = this.appSettings;

    appSettings.lastDataSyncDate = this.dateWrapper.getCurrentTime();
    this.applyAppSettings(appSettings);
  }

  updateJobsSyncFrequency(time: number) {
    const appSettings = this.appSettings;
    appSettings.jobsSyncFrequency = time;
    this.applyAppSettings(appSettings);
    this._updated.next(true);
  }

  updateDataSyncFrequency(syncFrequency: DataSyncFrequency) {
    const appSettings = this.appSettings;
    appSettings.dataSyncFrequency = syncFrequency;
    this.applyAppSettings(appSettings);
    this._updated.next(true);
  }

  private get technician(): Account {
    const currentUser = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
    return currentUser || {};
  }

  private get appSettings(): AppSettings {
    let appSettings = LocalStorageService.getItem('appSettings');
    if (appSettings === null) {
      this.applyDefaultSettings();
      appSettings = LocalStorageService.getItem('appSettings');
    }
    return appSettings || {};
  }

  private applyAppSettings(settings: AppSettings) {
    LocalStorageService.setItem('appSettings', settings);
  }
}
