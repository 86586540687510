<app-shell icon="arrow_back" titlePrefix="SERIAL#"
  [title]="(jobViewModel$ | async)?.jobEquipment?.customerEquipment?.serialNumber" [breadCrumbs]="(jobViewModel$ | async)?.breadCrumbs"
  (close)="goBackToEquipments()">

  <div style="color: #fff;background: #337ab7;padding: 0 15px 15px 15px;border-bottom: solid 1px #ccc;">
    <h3 style="margin-top: 0;color: #f1f1f1;">{{(jobViewModel$ | async)?.jobEquipment?.customerEquipment?.equipment?.name}}</h3>
  </div>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3 style="margin-bottom: 5px; padding: 15px;" class="color-primary"><span>PARTS</span></h3>
      </ng-template>
      <app-cards-vertical [cardsList]="(jobViewModel$ | async)?.jobEquipment?.customerEquipment?.jobParts | toJobPartCards"
        [deleteEnabled]="(readonly$ | async) === false" (delete)="deletePart($event)" (drilldown)="openQuantityEditor($event)">
      </app-cards-vertical>

      <div *ngIf="(jobViewModel$ | async)?.job.statusId === isInProgress" style="position: fixed; bottom: 15px; left: 15px; right: 15px">
        <div style="color: #333; text-align: center; width: 100%;">
          <button mat-raised-button color="primary" class="action-button" (click)="addPartClick()">
            <mat-icon class="custom-icon">playlist_add</mat-icon>Add Part
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <h3 style="margin-bottom: 5px; padding: 15px;" class="color-primary"><span>METER READING</span></h3>
      </ng-template>
      <app-camera-capture (photoTaken)="updateMeterReading($event)" [disable]="(readonly$ | async)"
        [photoDataUrl]="(jobViewModel$ | async)?.jobEquipment?.meterReadingPhoto?.data">

      </app-camera-capture>
    </mat-tab>
  </mat-tab-group>

</app-shell>
