import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ShellComponent } from './shell/shell.component';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner/spinner.component';
import { HideOnScrollUpDirective } from './hide-onscroll-up.directive';
import { TopBarComponent } from './top-bar/top-bar.component';
import { JobEquipmentCardsPipe } from './pipes/jobequipment-cards.pipe';
import { PartCardsPipe } from './pipes/part-cards.pipe';
import { JobCardsPipe } from './pipes/job-cards.pipe';
import { JobPartCardsPipe } from './pipes/job-part-cards.pipe';
import { EquipmentTypeCardsPipe } from './pipes/equipment-type-cards.pipe';
import { SplitPipe } from './split.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule
  ],
  declarations: [
    ShellComponent,
    SpinnerComponent,
    HideOnScrollUpDirective,
    SplitPipe,
    TopBarComponent,
    JobCardsPipe,
    JobEquipmentCardsPipe,
    JobPartCardsPipe,
    PartCardsPipe,
    EquipmentTypeCardsPipe
  ],
  exports: [
    ShellComponent,
    SpinnerComponent,
    HideOnScrollUpDirective,
    SplitPipe,
    TopBarComponent,
    JobCardsPipe,
    JobEquipmentCardsPipe,
    JobPartCardsPipe,
    PartCardsPipe,
    EquipmentTypeCardsPipe
  ]
})
export class SharedModule {}
