import { CustomerModel } from './customer';
import { JobEquipmentModel } from './job-equipment';
import { JobPartModel } from './job-part';
import { CommentModel } from './comment';
import { ModelBase } from './model-base';

export interface JobModel {
  id: number;
  uid: string;
  processingDate: Date;
  technicianId: number;
  statusId: number;
  currentStatusId: string;

  customerId: number;
  contactNumber: string;
  contactName: string;
  jobTypeId: string;
  svr: string;
  problem: string;
  comment: string;
  mileage: number;

  chargeable: boolean;
  customerSign: string;
  notified: string;
  sendMeterReading: boolean;
  sendDetailsToClient: boolean;

  isPublic: boolean;
  isDeleted: boolean;
  addedAt: Date;
  addedBy: string;
  updatedBy: string;
  timestamp: string;
  updatedAt: Date;

  customer: CustomerModel;
  jobEquipments: JobEquipmentModel[];
  jobParts: JobPartModel[];
  comments: CommentModel[];

  state: string; // for animation purposes, TODO: Refactor out
}

export interface JobCompleteModel {
  jobId: number;
  chargeable: boolean;
  sendMeterReading: boolean;
  sendDetailsToClient: boolean;
}

export class Job implements JobModel {

  customer: CustomerModel;
  jobEquipments: JobEquipmentModel[];
  jobParts: JobPartModel[];
  comments: CommentModel[];

  // constructor()
  constructor(
    public customerId: number,
    public contactNumber: string,
    public contactName: string,
    public jobTypeId: string,
    public svr: string,
    public problem: string,
    public comment: string,
    public mileage: number,

    public id: number,
    public uid: string,
    public processingDate: Date,
    public technicianId: number,
    public statusId: number,
    public currentStatusId: string,

    public chargeable: boolean,
    public customerSign: string,
    public notified: string,
    public sendMeterReading: boolean,
    public sendDetailsToClient: boolean,

    public isPublic: boolean,
    public isDeleted: boolean,
    public addedAt: Date,
    public addedBy: string,
    public updatedBy: string,
    public timestamp: string,
    public updatedAt: Date,
  ) {
    // Define navigation properties.
    // Making them non-enumerable will prevent them from being handled by indexedDB
    // when doing put() or add().
    Object.defineProperties(this, {
      customer: { value: [], enumerable: false, writable: true },
      jobEquipments: { value: [], enumerable: false, writable: true },
      jobParts: { value: [], enumerable: false, writable: true },
      comments: { value: [], enumerable: false, writable: true }
    });
  }

  state: string; // for animation purposes, TODO: Refactor out

  static create() {
    const job = new Job(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );

    job.customer = null;
    job.jobEquipments = [];
    job.jobParts = [];
    job.comments = [];

    return job;
  }
}
