import { Router, ActivatedRoute, Data, NavigationEnd } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { Subscription, Observable, Subject, BehaviorSubject } from 'rxjs';
import { AppEventsService, LocalStorageService, LAST_SCAN_KEY } from '.';
import { filter, map, tap, startWith } from 'rxjs/operators';

export const PREVIOUS_URL_KEY = 'prev-url';
export interface BackNavigation {
  viaNativeBack: boolean;
  previousUrl: string;
}

@Injectable()
export class NavigationService {
  currentParentUrl = '/';
  currentRouteData: Data = {};
  previousUrlSubscription: Subscription;
  navigatedViaNativeBackSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  previousUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  navigationViaNativeBack$: Observable<boolean>;
  previousUrl$: Observable<string>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appEventsService: AppEventsService,
    private zone: NgZone) {
      this.navigationViaNativeBack$ = this.navigatedViaNativeBackSubject.asObservable();
      this.previousUrl$ = this.previousUrlSubject.asObservable();
  }

  get routeTitle() {
    return this.currentRouteData.title ? this.currentRouteData.title : '';
  }

  navigateViaNativeBack(navigated: boolean) {
    this.navigatedViaNativeBackSubject.next(navigated);
  }

  getNavigationViaNativeBack$() {
    return this.navigationViaNativeBack$;
  }

  previousUrlNext(url: string) {
    this.previousUrlSubject.next(url);
  }

  getPreviousUrl$() {
    return this.previousUrl$;
  }

  setPreviousUrl(url) {
    LocalStorageService.setItem(PREVIOUS_URL_KEY, url);
  }

  getPreviousUrl() {
    LocalStorageService.getItem(PREVIOUS_URL_KEY);
  }

  setCurrentRouteData(data) {
    this.currentRouteData = data;
  }

  setCurrentParentUrl(url) {
    this.currentParentUrl = url;
  }

  gotoParent() {
    this.currentRouteData.parent ?
    this.router.navigate([this.currentRouteData.parent]) :
    this.router.navigate([this.currentParentUrl]);
  }

  gotoPendingJobs() {
    this.gotoJobs('activetab=1');
  }

  gotoAssignedJobs() {
    this.gotoJobs('activetab=2');
  }

  gotoCompleteJobs() {
    this.gotoJobs('activetab=3');
  }

  gotoLogin(queryParams: string = '') {
    // this.goto('login', queryParams);

    this.zone.run(() => {
      this.router.navigate(['login'])
      .then(result => {
        console.log(result);
      }, error => {
        console.log(error);
      });
    });
  }

  goto(url: string, queryParams: string = '') {
    LocalStorageService.removeItem(LAST_SCAN_KEY);
    this.setPreviousUrl(url);
    this.router.navigateByUrl(`${url}${queryParams.length > 0 ? '?' + queryParams : ''}`);
  }

  goForward(outlet) {
    this.router.navigate(
      [this.router.url.replace(outlet.activatedRouteData.state, outlet.activatedRouteData.forward)],
      { relativeTo: this.activatedRoute });
  }

  goBack(outlet) {
    this.router.navigate(
      [this.router.url.replace(outlet.activatedRouteData.state, outlet.activatedRouteData.back)],
      { relativeTo: this.activatedRoute });
  }

  private gotoJobs(queryParams: string = '') {
    this.goto('jobs', queryParams);
  }
}
