import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services';
import { ErrorModel } from '../models/error';

@Injectable()
export class ErrorService {
  baseApiEndpoint = 'api/errors';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ErrorService');
  }

  create(error: ErrorModel) {
    const url = `${this.baseApiEndpoint}`;

    return this.http.post(url, error, {})
      .pipe(
        catchError(this.handleError<ErrorModel>('createComment', null))
      );
  }
}
