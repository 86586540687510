import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CardModel } from '../models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { withLatestFrom, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { pushDummyState, popDummyState } from '../services/history-utility';

@Component({
  selector: 'app-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.css']
})
export class ItemSelectComponent implements OnInit, OnDestroy {
  itemCards: CardModel[] = [];
  selected = new Subject<number | string>();
  destroyed = new Subject();

  constructor(private dialogRef: MatDialogRef<ItemSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, cards$: Observable<CardModel[]> }) {
      if (data.cards$) {
        this.selected.asObservable().pipe(
          takeUntil(this.destroyed),
          withLatestFrom(data.cards$, (id, cards) => cards.find((card) => card.id === id || card.uid === id))
        ).subscribe((selected) => {
          this.dialogRef.close(selected);
        });
      }

    }

  ngOnInit(): void {
    // pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    // popDummyState();
  }

  selectedItem(itemId: number | string) {
    this.selected.next(itemId);
  }

  close() {
    this.dialogRef.close();
  }

}
