<mat-toolbar color="primary" class="fixed-top">
  <mat-toolbar-row>
    <button class="left-menus" mat-icon-button (click)="exitClick()">
      <mat-icon class="color-white">{{exitIcon}}</mat-icon>
    </button>
    <div *ngIf="breadcrumbs" class="breadcrumbs-container">
      <ng-container *ngFor="let item of breadcrumbs; last as isLast">
        <span style="font-size: 16px" [class.color-light-blue]="!isLast">{{item}}</span>
        <mat-icon class="mat-icon-16 color-light-blue" *ngIf="!isLast">chevron_right</mat-icon>
      </ng-container>
    </div>
    <span class="spacer"></span>

  </mat-toolbar-row>
</mat-toolbar>
