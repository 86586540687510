<figure>
  <div class="image-placeholder" (click)="takePhoto()">
    <button mat-fab color="primary" *ngIf="showPlaceHolder" [disabled]="disable">
      <i class="material-icons">photo_camera</i>
    </button>
    <img #photo src="#" alt="" [ngClass]=" {'hide': photoSrcUrl === '#' } ">
  </div>
</figure>



<div style="display: none;">
  <input type="file" #photoInput accept="image/*" capture (change)="uploadPhoto($event)">
  <canvas #imageCanvas></canvas>
</div>
