<app-shell [breadCrumbs]="['Load Data']" icon="arrow_back" (close)="goBack()" [open]="true">
  <mat-card>
    <mat-card-content>
      <mat-progress-spinner class="example-margin" [strokeWidth]="10" [color]="color" [mode]="mode" [value]="value"
        [diameter]="diameter">123
      </mat-progress-spinner>

      <div class="progress-container">
        <h2>{{value}}%</h2>
      </div>
      <mat-list role="list">
        <mat-list-item role="listitem" style="opacity: 1" *ngIf="activeTask">
          {{activeTask}}
        </mat-list-item>
        <mat-list-item role="listitem" *ngFor="let task of tasks; let i = index;" [ngStyle]="{ opacity: (10 - (i + 5))/10 }">
          {{task}}
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</app-shell>
