<mat-nav-list style="margin-bottom: 36px;">

  <a href="javascript;" mat-list-item (click)="addResolution($event)">
    <span mat-line><i class="material-icons mat-primary">assignment_turned_in</i><span class="action">Add Resolution</span></span>
    <span mat-line><span class="desc">Add job resolution</span></span>
  </a>
  <a href="javascript;" mat-list-item (click)="releaseJob($event)">
    <span mat-line><i class="material-icons mat-primary">watch_later</i><span class="action">Release</span></span>
    <span mat-line><span class="desc">Release job from your watchlist</span></span>
  </a>

  <a href="javascript;" *ngIf="disableSignature === false" mat-list-item (click)="getSignature($event)">
    <span mat-line><i class="material-icons mat-primary">edit</i><span class="action">Signature</span></span>
    <span mat-line><span class="desc">Get client's signature</span></span>
  </a>

  <a href="javascript;" *ngIf="disableComplete === false" mat-list-item (click)="completeJob($event)">
    <span mat-line><i class="material-icons mat-primary">check</i><span class="action">Complete</span></span>
    <span mat-line><span class="desc">Finalise & complete job</span></span>
  </a>
</mat-nav-list>
