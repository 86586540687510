<app-action-btn *ngIf="!snav.opened" style="display: block; top: 55px" [actions]="[{ action: 'add', icon: 'add'}]" bottomMargin="50px" (actionClick)="gotoAdd()">
</app-action-btn>

<mat-sidenav-container class="example-sidenav-container">
  <mat-sidenav #snav mode="over">
    <button class="js-menu-hide side-nav__hide material-icons" (click)="snav.toggle()">close</button>
    <header class="side-nav__header">
      <div style="position:absolute; width:90vw; top:50px; max-width: 400px">
        <img src="assets/icons/icon-96x96.png" style="margin:auto; display:block;" />
        <span class="version-container">v{{version}}</span>
      </div>
      <a mat-list-item (click)="toggleAccount()">My Account ></a>
      <button mat-icon-button class="top-right" (click)="logout()">
        <mat-icon aria-label="Logout">lock</mat-icon>
      </button>
    </header>
    <mat-nav-list class="side-nav__content">
      <a mat-list-item (click)="changeActiveTab(1);">Pending Jobs ></a>
      <a mat-list-item (click)="changeActiveTab(2);">Assigned Jobs ></a>
      <a mat-list-item (click)="changeActiveTab(3);">Completed Jobs ></a>
      <!-- <a mat-list-item routerLink="/jobs">Archived Jobs</a> -->
      <a mat-list-item (click)="syncApp()">Sync Data<span class="item-info"> ( Last sync:
          {{ lastSyncDate | date:'medium' }} )</span></a>
      <a mat-list-item (click)="resetApp()">Reset <span class="item-info">(Warning: this wipes all data stored)</span></a>
    </mat-nav-list>

    <div class="legend">
      <span><span class="symbol bg-pending"></span>Pending</span>
      <span><span class="symbol bg-assigned"></span>Assigned</span>
      <span><span class="symbol bg-inprogress"></span>In Progress</span>
      <span><span class="symbol bg-completed"></span>Completed</span>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="fixed-top">
      <mat-toolbar-row>
        <button class="left-menus" mat-icon-button (click)="snav.toggle()">
          <mat-icon class="color-white">menu</mat-icon>
        </button>
        <h3 style="margin-left: -40px; width: 100%; text-align: center;">{{activeTabLabel}}</h3>
        <span class="spacer"></span>

        <button class="right-menus" mat-icon-button (click)="reloadPending()">
          <mat-icon class="color-white">cached</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-tab-group color="primary" backgroundColor="primary" [selectedIndex]="activetab - 1"
      style="margin-bottom: 56px;" (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab>
        <app-cards-vertical #availableJobs [cardsList]="(pendingJobs$ | async) | toJobCards"
          (drilldown)="gotoJob($event)" (scrollDown)="showActionButton = false" (scrollUp)="showActionButton = true"
          class="scrollable"></app-cards-vertical>
      </mat-tab>
      <mat-tab>
        <app-cards-vertical #assignedJobs [cardsList]="(assignedJobs$ | async) | toJobCards"
          (drilldown)="gotoJob($event)" (scrollDown)="showActionButton = false" (scrollUp)="showActionButton = true"
          class="scrollable"></app-cards-vertical>
      </mat-tab>
      <mat-tab>
        <app-cards-vertical #assignedJobs [cardsList]="(completedJobs$ | async) | toJobCards"
          (drilldown)="gotoJob($event)" (scrollDown)="showActionButton = false" (scrollUp)="showActionButton = true"
          class="scrollable"></app-cards-vertical>
      </mat-tab>
    </mat-tab-group>

    <nav mat-tab-nav-bar mat-stretch-tabs backgroundColor="primary" style="position: fixed; z-index: 1001; bottom: 0; width: 100%;">
      <a mat-tab-link (click)="activetab = 1" [active]="activetab == 1">
        <div>
          <mat-icon class="nav-icon">playlist_add</mat-icon><br />
          <span>Pending</span>
        </div>
      </a>
      <a mat-tab-link (click)="activetab = 2" [active]="activetab == 2">
        <div>
          <mat-icon class="nav-icon">playlist_play</mat-icon><br />
          <span>Assigned</span>
        </div>
      </a>
      <a mat-tab-link (click)="activetab = 3" [active]="activetab == 3">
        <div>
          <mat-icon class="nav-icon">playlist_add_check</mat-icon><br />
          <span>Completed</span>
        </div>
      </a>
    </nav>
  </mat-sidenav-content>
</mat-sidenav-container>
