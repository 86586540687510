import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../api/authentication.service';
import { AppConfig } from '../app.config';
import { DexieService } from '../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  error = '';
  expired = false;

  get version() {
    return this.appConfig.version();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private appConfig: AppConfig,
    private db: DexieService) { }

  ngOnInit() {
    // reset login status
    this.authenticationService.removeCurrentUser();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.expired = (this.route.snapshot.queryParams['expired'] === 'true') ? true : false;
  }

  login() {
    this.loading = true;
    this.error = '';
    this.expired = false;
    this.authenticationService.login(this.model.username, this.model.password)
      .subscribe(() => {
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      }, (error) => {
        console.error(error);
        this.error = error;
        this.loading = false;
        this.resetApp();
      });
  }

  resetApp() {
    this.db.delete().then((_) => this.db.open() );
  }
}
