<app-shell icon="arrow_back" [breadCrumbs]="['Account']" (close)="close()">

  <mat-card class="exact-fit">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon mat-list-icon>account_circle</mat-icon>
      </div>
      <mat-card-title>{{account?.firstName}} {{account?.lastName}}</mat-card-title>
      <mat-card-subtitle>{{account?.userName}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-list>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Settings</h3>
        <!-- <mat-list-item>
        <mat-icon mat-list-icon>cloud_download</mat-icon>
        <h4 mat-line>Data Sync</h4>
        <mat-select #fontSize value="16px" [formControl]="dataSyncFrequency" (selectionChange)="updateDataSyncFrequency()">
          <mat-option *ngFor="let freq of dataSyncFrequencies" [value]="freq">{{freq}}</mat-option>
        </mat-select>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>cloud_download</mat-icon>
        <h4 mat-line>Jobs Sync</h4>
        <mat-select #fontSize value="16px" [formControl]="jobsSyncFrequency" (selectionChange)="updateJobsSyncFrequency()">
          <mat-option *ngFor="let freq of jobSyncFrequencies" [value]="freq.value">{{freq.name}}</mat-option>
        </mat-select>
      </mat-list-item> -->
        <mat-list-item>
          <mat-icon mat-list-icon>notifications_active</mat-icon>
          <h4 mat-line>Subscribe to notifications</h4>
          <mat-slide-toggle class="example-margin" color="primary" [checked]="subscribedToNotifications"
            (change)="subscribeToNotifications()">
          </mat-slide-toggle>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <!-- <mat-card-actions>
    <button mat-button>Save</button>
  </mat-card-actions> -->
  </mat-card>

</app-shell>
