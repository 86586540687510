import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services';
import { AuthenticationService } from './authentication.service';
import { ResponseStatus, CommentModel } from '../models';

@Injectable()
export class CommentService {
  baseApiEndpoint = 'api/comment';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('CommentService');
  }

  get(id): Observable<CommentModel[]> {
    const url = `${this.baseApiEndpoint}/${id}`;

    return this.http.get<CommentModel[]>(this.baseApiEndpoint)
      .pipe(
        catchError((error) => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.authenticationService.logout(true);
          }
          return this.handleError('getComment', [])(error);
        })
      );
  }

  create(comment: CommentModel) {
    const url = `${this.baseApiEndpoint}`;

    return this.http.post(url, comment, {})
      .pipe(
        catchError(this.handleError<CommentModel>('add resolution', null))
      );
  }

  update(comment: CommentModel) {
    const url = `${this.baseApiEndpoint}/${comment.id}`;

    return this.http.put(url, comment, {})
      .pipe(
        catchError(this.handleError<CommentModel>('updateComment', null))
      );
  }
}
