import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services';
import { AuthenticationService } from './authentication.service';
import { JobModel, JobCompleteModel } from '../models/job';
import { ResponseStatus, JobEquipmentModel, JobPartModel } from '../models';
import { MeterReadingPhotoModel } from '../models/meter-reading-photo';

@Injectable()
export class JobListService {
  baseApiEndpoint = 'api/jobs';
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('JobsService');
  }

  get(statusId: number = null): Observable<JobModel[]> {
    const query = statusId ? `?status=${statusId}` : '';

    return this.http.get<JobModel[]>(`${this.baseApiEndpoint}${query}`)
      .pipe(
        catchError((error) => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.authenticationService.logout(true);
          }
          return this.handleError('getJobs', [])(error);
        })
      );
  }

  getJobEquipment(jobId: number, jobUid: string = null): Observable<JobEquipmentModel> {

    return this.http.get<JobEquipmentModel>(`${this.baseApiEndpoint}/${jobId}/equipments/uid/${jobUid}`)
      .pipe(
        catchError((error) => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.authenticationService.logout(true);
          }
          return this.handleError('getJobEquipment', null)(error);
        })
      );
  }

  getJobEquipmentPart(jobId: number, jobUid: string = null): Observable<JobPartModel> {

    return this.http.get<JobPartModel>(`${this.baseApiEndpoint}/${jobId}/parts/uid/${jobUid}`)
      .pipe(
        catchError((error) => {
          if (error.status === ResponseStatus.Unauthorized) {
            this.authenticationService.logout(true);
          }
          return this.handleError('getJobEquipmentPart', null)(error);
        })
      );
  }

  search(term: string): Observable<JobModel[]> {
    term = term.trim();

    const options = term ?
      { params: new HttpParams().set('name', term) } : {};

    return this.http.get<JobModel[]>(this.baseApiEndpoint, options)
      .pipe(
        catchError(this.handleError<JobModel[]>('searchJobs', []))
      );
  }

  assign(id: number, device: string) {
    const url = `${this.baseApiEndpoint}/${id}/assign`;

    return this.http.put(url, { device }, {}).pipe(
        catchError(this.handleError<JobModel>('assignJob', null))
      );
  }

  start(id: number, device: string) {
    const url = `${this.baseApiEndpoint}/${id}/start`;

    return this.http.put(url, { device }, {}).pipe(
        catchError(this.handleError<JobModel>('startJob', null))
      );
  }

  release(jobId: number, device: string, comment: string) {
    const url = `${this.baseApiEndpoint}/${jobId}/release`;

    return this.http.put(url, { device, comment }, {}).pipe(
        catchError(this.handleError<JobModel[]>('releaseJob', []))
      );
  }

  complete(job: JobCompleteModel, device: string) {
    const url = `${this.baseApiEndpoint}/${job.jobId}/complete`;

    const body = {
      job: job,
      device,
      sendMeterReading: job.sendMeterReading,
      sendDetailsToClient: job.sendDetailsToClient
    };

    return this.http.put(url, body, {})
      .pipe(
        catchError(this.handleError<JobCompleteModel[]>('completeJob', []))
      );
  }

  save(job: JobModel) {
    const url = `${this.baseApiEndpoint}/${job.id}`;

    return this.http.put(url, job, {})
      .pipe(
        catchError(this.handleError<JobModel>('saveJob', null))
      );
  }

  addJobEquipment(jobId: number, jobEquipment: JobEquipmentModel) {
    const url = `${this.baseApiEndpoint}/${jobId}/equipments`;

    return this.http.post(url, jobEquipment, {})
      .pipe(
        catchError(this.handleError<number>('addJobEquipment', null))
      );
  }

  deleteJobEquipment(jobId: number, jobEquipmentId: number) {
    const url = `${this.baseApiEndpoint}/${jobId}/equipments/${jobEquipmentId}`;

    return this.http.delete(url, {})
      .pipe(
        catchError(this.handleError<boolean>('deleteJobEquipment', null))
      );
  }

  deleteJobEquipmentByUid(jobId: number, jobEquipmentUid: string) {
    const url = `${this.baseApiEndpoint}/${jobId}/equipments/uid/${jobEquipmentUid}`;

    return this.http.delete(url, {})
      .pipe(
        catchError(this.handleError<boolean>('deleteJobEquipment', null))
      );
  }

  addJobEquipmentPart(jobId: number, customerEquipmentId: number, jobPart: JobPartModel) {
    const url = `${this.baseApiEndpoint}/${jobId}/equipments/${customerEquipmentId}/parts`;

    return this.http.post(url, jobPart, {})
      .pipe(
        catchError(this.handleError<number>('addJobEquipmentPart', null))
      );
  }

  updateJobEquipmentPart(jobId: number, uid: string, jobPart: JobPartModel) {
    const url = `${this.baseApiEndpoint}/${jobId}/parts/uid/${uid}`;

    return this.http.put(url, jobPart, {})
      .pipe(
        catchError(this.handleError<number>('updateJobEquipmentPart', null))
      );
  }

  updateJobEquipmentMeterReading(jobId: number, jobEquipmentId: number, meterReading: MeterReadingPhotoModel) {
    const url = `${this.baseApiEndpoint}/${jobId}/equipments/${jobEquipmentId}/meterreading/`;
    console.log(url);
    return this.http.post(url, meterReading, {})
      .pipe(
        catchError(this.handleError<number>('updateJobEquipmentMeterReading', null))
      );
  }

  getJobEquipmentMeterReading(meterReadingId: number) {
    const url = `api/meterreading/${meterReadingId}`;
    console.log(url);
    return this.http.get(url, {})
      .pipe(
        catchError(this.handleError<number>('getJobEquipmentMeterReading', null))
      );
  }

  deleteJobEquipmentPart(jobId: number, jobPartId: number) {
    const url = `${this.baseApiEndpoint}/${jobId}/parts/${jobPartId}`;

    return this.http.delete(url, {})
      .pipe(
        catchError(this.handleError<boolean>('deleteJobEquipmentPart', null))
      );
  }

  deleteJobEquipmentPartByUid(jobId: number, jobPartUid: string) {
    const url = `${this.baseApiEndpoint}/${jobId}/parts/uid/${jobPartUid}`;

    return this.http.delete(url, {})
      .pipe(
        catchError(this.handleError<boolean>('deleteJobEquipmentPart', null))
      );
  }

  create(job: JobModel) {
    const url = `${this.baseApiEndpoint}`;

    return this.http.post(url, job, {})
      .pipe(
        catchError(this.handleError<JobModel>('createJob', null))
      );
  }

  updateSignature(jobId: number, signature: string) {
    const url = `${this.baseApiEndpoint}/${jobId}/signature`;

    return this.http.put(url, { signature }, {})
      .pipe(
        catchError(this.handleError<JobModel>('updateSignature', null))
      );
  }
}
