import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent {
  barcode = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<ScanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (this.data && this.data.barcode) {
        this.barcode.setValue(data.barcode);
      }
    }

  addSerial() {
    this.dialogRef.close(this.barcode.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
