import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Injectable()
export class AppEventsService {
  private userLoginExpired = new Subject<boolean>();
  private exitApp = new Subject<boolean>();
  private alert = new Subject<any>();

  isUserLoginExpired = this.userLoginExpired.asObservable().pipe(startWith(false));
  exitAppTrigger = this.exitApp.asObservable();
  alerts = this.alert.asObservable();

  UserLoggedOut(expired: boolean) {
    this.userLoginExpired.next(expired);
  }

  emitExitAppTrigger(exit: boolean) {
    this.exitApp.next(exit);
  }

  emitAlert(alert: string, action: string = '') {
    this.alert.next({ alert, action });
  }
}
