<app-shell icon="close" [breadCrumbs]="['Release Job']" (close)="close()">
  <mat-card style="padding: 14px;">
    <mat-form-field class="full-width">
      <textarea matInput placeholder="Comments" [formControl]="comment"></textarea>
    </mat-form-field>

    <div style="color: #333; text-align: center;">
      <button mat-raised-button color="primary" class="action-button" (click)="release()" [disabled]="comment.valid === false">
        <mat-icon class="custom-icon">remove_from_queue</mat-icon>Release
      </button>
    </div>

  </mat-card>
</app-shell>
