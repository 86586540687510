import { Directive, ElementRef, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { startWith, pairwise, distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
  selector: '[appHideOnScrollUp]'
})
export class HideOnScrollUpDirective implements AfterViewInit, OnDestroy {

  constructor(private el: ElementRef) { }

  lastScrollPosition: Subject<number> = new Subject<number>();
  scrollingDownSub: Subscription;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    this.lastScrollPosition.next(event.srcElement.scrollingElement.scrollTop);
  }

  // contentChildren are set
  ngAfterViewInit() {
    this.scrollingDownSub = this.lastScrollPosition.asObservable().pipe(
      pairwise(),
      map(([a, b]) => {
        console.log(a, b);
        return b - a > 0;
      }),
      distinctUntilChanged()
    )
      .subscribe((v) => this.toggleOnScroll(v));
  }

  ngOnDestroy() {
    this.scrollingDownSub.unsubscribe();
  }

  private toggleOnScroll(scrollDown: boolean) {
    if (!this.el) {
      return;
    }

    console.log(scrollDown);
    if (scrollDown === false) {
      this.el.nativeElement.style.display = 'block';
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
