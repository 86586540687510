import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {
  private readonly _version: string = '1.0.6';

  public version(): string {
    return this._version;
  }
}
