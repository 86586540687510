export const pushDummyState = (modalId: string) => {
  history.pushState({
    modal : true,
    modalId,
    desc : 'fake state for our modal'
  }, null);
};

export const popDummyState = () => {
  if (window.history.state.modal) {
    history.back();
  }
};

