<div #card class="card--content" (click)="emitClickEvent()" [ngClass]="{'selected': selected }">
  <span class="icon">
    <mat-icon class="size-48">{{inputObject?.icon}}</mat-icon>
  </span>
  <span>{{inputObject?.header}}</span>
  <span class="desc">
    {{ inputObject?.lines.length > 0 ? inputObject.lines[0] : '' }}<br>
    {{ inputObject?.lines.length > 1 ? (inputObject.lines[1]) : '' }}
  </span>
</div>
