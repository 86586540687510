import { Observable, Subject } from 'rxjs';
import { ScanItem } from './scan-item';
import { RouteConfigLoadEnd } from '@angular/router';
import { ScanType } from './scan-type.enum';

export class NFCFactory {
  private writer: any;
  private reader: any;
  public data = new Subject<ScanItem>();

  constructor() {
    try {
      // @ts-ignore
      if (NDEFReader) {
        this.init();
      }
    } catch (e) {
      console.error('NFC api not implemented');
    }
  }

  init() {
    // @ts-ignore
    // this.writer = new NDEFWriter();
    // @ts-ignore
    this.reader = new NDEFReader();

    this.reader.onreading = event => {
      const message = event.message;
      /* if (message.records[0].recordType === 'empty') {
        this.writer.push({
          url: '/custom/path',
          records: [{ recordType: 'text', data: 'Hello World' }]
        });
        return;
      } */
      for (const i of Object.keys(message.records)) {
        // this.consoleLog(`i: ${i}`);
        const record = message.records[i];
        // this.consoleLog(`record.recordType: ${record.recordType}`);
        switch (record.recordType) {
          case 'text':
            // this.consoleLog(`record.recordType: ${record.recordType}`);
            // for (const j of Object.keys(record)) {
            //  this.consoleLog(`j: ${j}`);
            // }
            const scannedParts: string[] = record.text() ? record.text().split('-') : [];
            let type, name = '', value = '';

            if (scannedParts.length > 0) {
              name = scannedParts[0];
              value = scannedParts[1];

              if (scannedParts[0].indexOf('CE') > -1) {
                type = ScanType.CustomerEquipment;
              } else if (scannedParts[0].indexOf('E') > -1) {
                type = ScanType.Equipment;
              } else if (scannedParts[0].indexOf('P') > -1) {
                type = ScanType.Part;
              }
            }

            this.data.next({
              name,
              value,
              type
            } as ScanItem);
            break;
          /* case 'url':
            this.consoleLog(`URL: ${record.toText()}`);
            break;
          case 'json':
            this.consoleLog(`JSON: ${record.toJSON().myProperty}`);
            break;
          case 'opaque':
            if (record.mediaType.startsWith('image/')) {
              const blob = new Blob([record.toArrayBuffer()], { type: record.mediaType });

              const img = document.createElement('img');
              img.src = URL.createObjectURL(blob);
              // img.onload = () => window.URL.revokeObjectURL(this.src);

              document.body.appendChild(img);
            }
            break; */
        }
      }
    };

  }

  consoleLog(data) {
    const logElement = document.getElementById('log');
    logElement.innerHTML += '\n' + data;
  }

  scan() {
    if (!this.reader) {
      return;
    }

    this.reader.scan();
  }
}
