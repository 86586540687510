<div class="info-card">
  <h2>{{customer?.venueNumber}}</h2>
  <h3>{{customer?.name}}</h3>

  <p class="info">
    <span class="desc">
      <mat-icon class="icon">person</mat-icon><span>Manager</span>
    </span>
    <span class="data">{{customer?.manager}}.</span>
  </p>
  <p class="info">
    <span class="desc">
      <mat-icon class="icon">place</mat-icon><span>Address</span>
    </span>
    <span class="data">{{customer?.physicalAddress}}.</span>
  </p>
  <p class="info">
    <span class="desc">
      <mat-icon class="icon">email</mat-icon><span>Email</span>
    </span>
    <span class="data">{{customer?.accountsEmail}}.</span>
  </p>
  <p class="info">
    <span class="desc">
      <mat-icon class="icon">smartphone</mat-icon><span>Phone</span>
    </span>
    <span class="data">{{customer?.phoneNumber}}.</span>
  </p>
  <p class="info">
    <span class="desc">
      <mat-icon class="icon">scanner</mat-icon><span>Fax</span>
    </span>
    <span class="data">{{customer?.fax}}.</span>
  </p>
</div>
