import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';

@Injectable()
export class NotificationService {
  currentParentUrl = '/';

  constructor(private windowRef: WindowRefService) { }

  notify() {
    console.log('notifying');
    // Let's check if the browser supports notifications
    if (!('Notification' in this.windowRef.nativeWindow)) {
      alert('This browser does not support system notifications');
      // This is not how you would really do things if they aren't supported. :)
    } else if ((Notification as any).permission === 'granted') {
      console.log('granted');
      this.showNotification('New Jobs Available');
    } else if ((Notification as any).permission !== 'denied') {
      const showNotification = this.showNotification;
      Notification.requestPermission(function (permission) {
        console.log('asking permission');
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          showNotification('New Jobs Available');
        }
      });
    }
  }

  showNotification(title) {
    console.log('showing notifications');
    const options = {
      icon: 'assets/icons/icon-48x48.png',
      badge: 'assets/icons/icon-48x48.png',
      tag: 'newjobs',
      // Star Wars shamelessly taken from the awesome Peter Beverloo
      // https://tests.peter.sh/notification-generator/
      vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500]
    } as NotificationOptions;
    // const notification = new Notification(title, options);
    // setTimeout(notification.close.bind(notification), 4000);

    return navigator.serviceWorker.ready.then(function (serviceWorker) {
      return serviceWorker.showNotification(title, options);

    }).catch(function (exception) { alert(exception); });
  }
}
