<app-shell icon="close" [breadCrumbs]="job?.id ? ['Edit Job'] : ['Add Job']" (close)="close()">
  <div class="container">
    <form [formGroup]="jobForm" class="form">
      <mat-form-field class="full-width">
        <input matInput placeholder="Processing Date" [disabled]="true" [value]="convertDate(job?.processingDate)">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput placeholder="Customer" #customer aria-label="Customer" [matAutocomplete]="auto"
          [formControl]="customerAutoComplete" (focus)="scrollToView(customer)">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateCustomer($event)" [displayWith]="displayFn">
          <mat-option *ngFor="let customer of customers | async;" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-autocomplete>
        <span matSuffix *ngIf="loadingCustomers === true">
          <app-spinner color="#ccc" width="0.15rem"></app-spinner>
        </span>
      </mat-form-field>
      <input style="display: none" formControlName="customerId">

      <mat-form-field class="full-width">
        <input matInput #contactName placeholder="Contact Name" formControlName="contactName"
          (focus)="scrollToView(contactName)">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput #contactNumber placeholder="Contact #" formControlName="contactNumber"
          (focus)="scrollToView(contactNumber)">
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea matInput #problem placeholder="Fault" formControlName="problem"
          (focus)="scrollToView(problem)"></textarea>
      </mat-form-field>

      <mat-form-field class="full-width">
        <textarea matInput #comment placeholder="Comment" formControlName="comment"
          (focus)="scrollToView(comment)"></textarea>
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput #svr placeholder="SVR" formControlName="svr" (focus)="scrollToView(svr)">
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput #mileage placeholder="Mileage(km)" formControlName="mileage" type="number" (focus)="scrollToView(mileage)">
      </mat-form-field>
    </form>

    <div style="color: #333; text-align: center;">
      <button mat-raised-button color="primary" class="action-button" (click)="save()"
        [disabled]="jobForm.valid === false">
        <mat-icon class="custom-icon">{{job?.id ? 'save' : 'playlist_add'}}</mat-icon>{{job?.id ? 'Save Changes' : 'Add Job'}}
      </button>
    </div>
  </div>
</app-shell>
