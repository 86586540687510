<div #cardmd class="card" [ngClass]="{ 'selected': selected }">
    <span class="icon" [ngClass]="{'pending': isPending, 'in-progress': inProgress, 'assigned': isAssigned, 'complete': isComplete}">
      {{inputObject.id ? inputObject.id : '???'}}
    </span>
    <p [ngClass]="{ 'space-right': deleteEnabled }">
      <a class="easier-to-select-text" (doubleclick)="emitDoubleClickEvent()"
      (click)="emitTapEvent()"
      (press)="emitPressEvent()">
        <strong>{{inputObject?.header}}</strong><br>
        <span class="desc">
          {{ inputObject?.lines.length > 0 ? inputObject.lines[0] : '' }}<br>
          {{ inputObject?.lines.length > 1 ? (inputObject.lines[1]) : '' }}
        </span>
      </a>
      <span class="drill-down">
        <mat-icon aria-label="Drill down" *ngIf="inputObject.type === 'EquipmentType'">keyboard_arrow_right</mat-icon>
      </span>
    </p>
    <div class="top-right" *ngIf="deleteEnabled">
      <button mat-icon-button (click)="emitDeleteEvent($event)">
        <mat-icon aria-label="Delete">close</mat-icon>
      </button>
    </div>
  </div>
