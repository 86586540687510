import Dexie from 'dexie';
import 'dexie-observable';

import { Injectable } from '@angular/core';
import { DB_NAME } from '../shared/constants';
import {
  JobModel,
  CustomerModel,
  EquipmentModel,
  EquipmentTypeModel,
  PartModel,
  ResolutionModel,
  CustomerEquipmentModel,
  JobEquipmentModel,
  JobPartModel,
  CommentModel,
  Job,
  JobEquipment,
  CustomerEquipment
} from '../models';
import { MeterReading, MeterReadingPhotoModel } from '../models/meter-reading-photo';

export const JOBS_TABLE_NAME = 'jobs';
export const EQUIPMENTS_TABLE_NAME = 'equipments';
export const EQUIPMENT_TYPES_TABLE_NAME = 'equipmentTypes';
export const CUSTOMERS_TABLE_NAME = 'customers';
export const CUSTOMER_EQUIPMENTS_TABLE_NAME = 'customerEquipments';
export const RESOLUTIONS_TABLE_NAME = 'resolutions';
export const PARTS_TABLE_NAME = 'parts';

@Injectable()
export class DexieService extends Dexie {
  jobs: Dexie.Table<JobModel, number>;
  jobEquipments: Dexie.Table<JobEquipmentModel, string>;
  meterReadings: Dexie.Table<MeterReadingPhotoModel, number>;
  jobParts: Dexie.Table<JobPartModel, string>;
  comments: Dexie.Table<CommentModel, number>;
  customers: Dexie.Table<CustomerModel, number>;
  equipmentTypes: Dexie.Table<EquipmentTypeModel, number>;
  equipments: Dexie.Table<EquipmentModel, number>;
  parts: Dexie.Table<PartModel, number>;
  resolutions: Dexie.Table<ResolutionModel, number>;
  customerEquipments: Dexie.Table<CustomerEquipmentModel, string>;

  constructor() {
    console.log('DexieService');

    super(DB_NAME);

    this.version(1).stores({
      jobs: '++id, statusId, technicianId',
      customers: '++id, accountsEmail, name, venueNumber',
      jobEquipments: '&uid, id, jobId',
      jobParts: '&uid, id, jobId, partId, customerEquipmentId, customerEquipmentUid',
      comments: '++id, jobId, serialNumber',
      meterReadings: '++jobEquipmentId',
      equipmentTypes: '++id, parentTypeId, name',
      equipments: '++id, name, serialNumber, barcode, equipmentTypeId',
      parts: 'id++, name, equipmentTypeId, serialNumber, barcode',
      resolutions: '++id, resolution',
      customerEquipments: '&uid, customerId, equipmentId, serialNumber'
    });

    this.jobs.mapToClass(Job);
    this.jobEquipments.mapToClass(JobEquipment);
    this.customerEquipments.mapToClass(CustomerEquipment);
    this.meterReadings.mapToClass(MeterReading);
  }
}
