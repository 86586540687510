import { Injectable } from '@angular/core';
import { concatMap, switchMap, } from 'rxjs/operators';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { JobModel, JobStatusType } from '../models';
import { JobListService } from '../api';
import { TEN_MINUTES_IN_MILISECONDS } from '../shared/constants';

@Injectable()
export class JobPollingService {

  private polledJobs$: Observable<JobModel[]>;
  private load$ = new BehaviorSubject('');

  constructor(private jobsApi: JobListService) {}

  refresh() {
    this.load$.next('');
  }

  getPolledJobs$(intervalInMiliSeconds: number = TEN_MINUTES_IN_MILISECONDS): Observable<JobModel[]> { // default 10mins
    if (!this.polledJobs$) {
      this.init(intervalInMiliSeconds);
    }

    return this.polledJobs$;
  }

  private init(intervalInMiliSeconds: number) {
    const jobs$ = this.jobsApi.get(JobStatusType.Pending);

    this.polledJobs$ = this.load$.pipe(
      switchMap((_l) => timer(0, intervalInMiliSeconds).pipe(
        concatMap((_t) => jobs$),
       )
      )
    );
  }
}
