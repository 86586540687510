
    <div style="color: #fff;background: #337ab7;padding: 0 15px 15px 15px;">
      <h3 style="margin-top: 0;color: #f1f1f1;">{{job?.problem}}</h3>
    </div>
    <div style="padding: 15px; color: #333; text-align: center;">
      <button *ngIf="isPending()" mat-raised-button color="primary" class="action-button" (click)="assignClick()">
        <mat-icon class="custom-icon">assignment_ind</mat-icon>Assign To Me
      </button>
      <button *ngIf="isAssigned()" mat-raised-button color="primary" class="action-button" (click)="startClick()">
        <mat-icon class="custom-icon">access_time</mat-icon>Start Job
      </button>
      <button *ngIf="isInProgress()" mat-raised-button color="primary" class="action-button" (click)="editJob()">
        <mat-icon class="custom-icon">create</mat-icon>Edit Job
      </button>
      <mat-divider [class]="'border-bottom'+job?.statusId" ></mat-divider>
    </div>
    <div style="padding: 0 15px; color: #333; position: relative;">
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">smartphone</mat-icon><span>{{job?.contactName}} (contact)</span>
        </span>
        <span class="data">
          <a *ngIf="job?.contactNumber" [href]="phoneLink(job?.contactNumber)">
            {{job?.contactNumber}}
            <mat-icon style="vertical-align: bottom; margin-left: 10px">arrow_forward</mat-icon>
          </a>
        </span>
      </p>
      <mat-divider></mat-divider>
    </div>
    <div>
      <a (click)="equipmentsClick()" style="color: #337ab7; display: block">
        <h3 style="margin: 5px; padding: 15px;">
          <span style="margin-right: 10px">EQUIPMENTS</span>
          <mat-icon style="vertical-align: bottom;">arrow_forward</mat-icon>
        </h3>
      </a>
      <mat-divider></mat-divider>
    </div>
    <div>
      <a (click)="resolutionsClick()" style="color: #337ab7; display: block">
        <h3 style="margin: 5px; padding: 15px;">
          <span style="margin-right: 10px">RESOLUTIONS</span>
          <mat-icon style="vertical-align: bottom;">arrow_forward</mat-icon>
        </h3>
      </a>
      <mat-divider></mat-divider>
    </div>
    <div style="padding: 15px; color: #333; margin-bottom: 56px;">
      <h3 style="margin-bottom: 5px;"><span class="color-light-gray">VENUE</span>{{job?.customer?.venueNumber}}</h3>
      <h4 style="margin-top: 0;">{{job?.customer?.name}}</h4>
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">person</mat-icon><span>Manager</span>
        </span>
        <span class="data">{{job?.customer?.manager}}</span>
      </p>
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">place</mat-icon><span>Address</span>
        </span>
        <span class="data">
          <a *ngIf="job?.customer?.physicalAddress" [href]="googleMapSearchLink(job?.customer?.physicalAddress)" target="_blank">
            {{job?.customer?.physicalAddress}}
            <mat-icon style="vertical-align: bottom; margin-left: 10px">arrow_forward</mat-icon>
          </a>
        </span>
      </p>
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">email</mat-icon><span>Email</span>
        </span>
        <span class="data">
          <a *ngIf="job?.customer?.accountsEmail" [href]="emailLink(job?.customer?.accountsEmail)">
            <span style="display: block;" *ngFor="let email of (job?.customer?.accountsEmail | splitBy:';')">{{email}}</span>
            <mat-icon style="vertical-align: bottom; margin-left: 10px">arrow_forward</mat-icon>
          </a>
        </span>
      </p>
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">smartphone</mat-icon><span>Phone</span>
        </span>
        <span class="data">
          <a *ngIf="job?.customer?.phoneNumber" class="color-primary" [href]="phoneLink(job?.customer?.phoneNumber)">
            {{job?.customer?.phoneNumber}}
            <mat-icon style="vertical-align: bottom; margin-left: 10px">arrow_forward</mat-icon>
          </a>
        </span>
      </p>
      <p class="info">
        <span class="desc">
          <mat-icon class="icon">scanner</mat-icon><span>Fax</span>
        </span>
        <span class="data">{{job?.customer?.fax}}</span>
      </p>
      <mat-divider></mat-divider>
    </div>
