import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { PartModel, JobEquipmentModel, JobPartModel } from '../models';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NumberSpinnerComponent } from '../number-spinner/number-spinner.component';
import { JobService } from '../services/job.service';
import { popDummyState, pushDummyState } from '../services/history-utility';

@Component({
  selector: 'app-equipment-part-add',
  templateUrl: './equipment-part-add.component.html',
  styleUrls: ['./equipment-part-add.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentPartAddComponent implements OnInit, OnDestroy {
  constructor(
    private jobService: JobService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EquipmentPartAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      jobId: number,
      jobEquipment: JobEquipmentModel,
      parts: PartModel[]
    }) {}

  ngOnInit(): void {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    popDummyState();
  }

  nfcScan() {
    console.log('scanning');
  }

  partSelected(id: number) {
    const dialogRef = this.dialog.open(NumberSpinnerComponent, {
      width: '300px',
      data: { value: 0, actionButtonText: 'Add' }
    });

    dialogRef.afterClosed().subscribe(quantity => {
      if (quantity) {

        const jobPart = {
          id: 0,
          customerEquipmentId: this.data.jobEquipment.customerEquipmentId,
          customerEquipmentUid: this.data.jobEquipment.customerEquipment.uid,
          jobId: this.data.jobId,
          partId: id,
          // partName: ?? TODO: custom or pull from parts table'
          quantity
        } as JobPartModel;

        this.jobService.addJobEquipmentPart(jobPart).subscribe((jobPartId) => {
          console.log('part selected', id);
          this.dialogRef.close(jobPartId);
        });
      }
    });
  }

  close() {
    console.log('close');
    this.dialogRef.close();
  }

}
