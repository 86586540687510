export enum ActionTypeEnum {
  AddResolution = 'ADD_RESOLUTION',
  ReleaseJob = 'RELEASE_JOB',
  GetSignature = 'GET_SIGNATURE',
  AddTask = 'ADD_TASK',
  SaveJob = 'SAVE_JOB',
  StartJob = 'START_JOB',
  AssignJob = 'ASSIGN_JOB',
  CompleteJob = 'COMPLETE_JOB'
}
