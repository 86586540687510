import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CardModel } from '../models';

@Component({
  selector: 'app-cards-horizontal',
  templateUrl: './cards-horizontal.component.html',
  styleUrls: ['./cards-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsHorizontalComponent {
  @Input() cardList: CardModel[];
  @Input() selectedId: number;
  @Output() select = new EventEmitter<number>();

  viewPortItems: CardModel[];

  constructor() { }

  setSelected(id) {
    this.selectedId = id;
    this.select.emit(id);
  }

}
