<app-shell icon="close" [breadCrumbs]="['Add Equipment']" (close)="close()">

  <!-- <pre id="log" style="position: absolute; bottom: 20px; left: 20px"></pre> -->
  <ul class="arrows">
    <li *ngFor="let card of breadCrumbs"><a href="javascript:;" (click)="breadCrumbClicked(card.id)">{{card.header}}</a>
    </li>
  </ul>
  <!--
<button mat-raised-button (click)="loadBySerialNumber('1070105')">Simulate Equipment Scan</button>
<button mat-raised-button (click)="add('12126253')">Simulate Customer Equipment Scan</button>
-->
  <app-cards-vertical [cardsList]="currentEquipmentTypes" [selectedIds]="selected ? [selected.id] : []"
    (drilldown)="cardClicked($event)">
  </app-cards-vertical>

  <app-action-btn [actions]="[{ action: 'add', icon: 'nfc'}]" (actionClick)="nfcScan()"></app-action-btn>

</app-shell>
