<mat-toolbar color="primary" class="fixed-top">
    <mat-toolbar-row>
      <mat-icon>home</mat-icon>
      <span class="example-spacer"></span>

      <mat-icon class="example-icon">cloud_off</mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>lock</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
