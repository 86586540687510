import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { CardModel } from '../models';

@Component({
  selector: 'app-card-lg',
  templateUrl: './card-lg.component.html',
  styleUrls: ['./card-lg.component.scss']
})
export class CardLgComponent implements OnChanges {
  @Input() inputObject: CardModel;
  @Input() selected: boolean;
  @Output() cardSelect = new EventEmitter<number>();
  @ViewChild('card') cardElement: ElementRef;

  constructor() { }

  ngOnChanges(changes) {
    if (changes.selected) {
      this.scrollIntoView();
    }
  }

  scrollIntoView() {
    if (this.selected === true) {
      this.cardElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  emitClickEvent() {
    this.cardSelect.emit(this.inputObject.id);
  }

}
