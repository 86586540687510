
export interface MeterReadingPhotoModel {
  jobEquipmentId: number;
  data: string;
  photo: string;
}
export class MeterReading implements MeterReadingPhotoModel {
  constructor(
    public jobEquipmentId: number,
    public data: string,
    public photo: string) {
  }

}
