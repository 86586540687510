import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionModel } from '../models/action';
import { actionButtonAnimations } from './action-btn.animation';

@Component({
  selector: 'app-action-btn',
  templateUrl: './action-btn.component.html',
  styleUrls: ['./action-btn.component.scss'],
  animations: actionButtonAnimations
})
export class ActionBtnComponent {
  @Input() actions: ActionModel[];
  @Input() disabled: boolean;
  @Input() bottomMargin = '15px';
  @Output() actionClick = new EventEmitter<string>();

  buttons = [];
  fabTogglerState = 'inactive';

  constructor() { }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.actions;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    if (this.actions.length > 0) {
      this.buttons.length > 0 ? this.hideItems() : this.showItems();
    } else {
      this.emitActionClick(this.actions[0].action);
    }
  }

  emitActionClick(action: string) {
    this.actionClick.emit(action);
    this.hideItems();
  }
}
