import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LocalStorageService } from './local-storage';
import { LOGGEDIN_USER_STORAGE } from '../shared/constants';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
          catchError((error, caught) => this.handleSessionExpiry(error, next))
        );
    }

    handleSessionExpiry(error, next) {
      if (error.status === 401) {
        console.log('401 error');
        this.router.navigate(['/login']);
      }
      return throwError(error);
    }
}
