import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { CardModel } from '../models';

@Component({
  selector: 'app-card-md',
  templateUrl: './card-md.component.html',
  styleUrls: ['./card-md.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMdComponent implements OnInit, OnChanges {
  @Input() inputObject: CardModel;
  @Input() selected: boolean;
  @Input() deleteEnabled = false;
  @Output() select = new EventEmitter<number | string>();
  @Output() drilldown = new EventEmitter<number | string>();
  @Output() assign = new EventEmitter<number | string>();
  @Output() delete = new EventEmitter<number | string>();
  @ViewChild('cardmd') cardElement: ElementRef;

  get isPending() { return this.inputObject.status === 'Pending'; }
  get inProgress() { return this.inputObject.status === 'WIP'; }
  get isAssigned() { return this.inputObject.status === 'Assigned'; }
  get isComplete() { return this.inputObject.status === 'Completed'; }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.selected) {
      this.scrollIntoView();
    }
  }

  scrollIntoView() {
    // if (this.selected === true &&
    //   this.cardElement && this.cardElement.nativeElement &&
    //   this.cardElement.nativeElement.parentElement &&
    //   this.cardElement.nativeElement.parentElement.parentElement) {

    //   const parentContainerOuter = this.cardElement.nativeElement.parentElement.parentElement.getBoundingClientRect();
    //   const parentContainerInner = this.cardElement.nativeElement.parentElement.getBoundingClientRect();
    //   const me = this.cardElement.nativeElement.getBoundingClientRect();

    //   const scrollTop = parentContainerInner.top + 31;

    //   this.cardElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', scrollTop });
    // }
  }

  emitClickEvent() {
    this.drilldown.emit(this.inputObject.uid || this.inputObject.id);
  }

  emitDoubleClickEvent() {
    this.select.emit(this.inputObject.uid || this.inputObject.id);
  }

  emitTapEvent() {
    this.drilldown.emit(this.inputObject.uid || this.inputObject.id);
  }

  emitPressEvent() {
    this.select.emit(this.inputObject.uid || this.inputObject.id);
  }

  emitAssignEvent() {
    this.assign.emit(this.inputObject.uid || this.inputObject.id);
  }

  emitDeleteEvent(event) {
    this.delete.emit(this.inputObject.uid || this.inputObject.id);
  }
}
