import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { of, Observable, Subject } from 'rxjs';
import { AppService } from '../app.service';
import { JobStatusType, JobModel } from '../models/index';
import { NavigationService } from '../services/navigation.service';
import { CardsVerticalComponent } from '../cards-vertical/cards-vertical.component';
import { AuthenticationService } from '../api/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { CanComponentDeactivate } from '../guards/save.guard';
import { AppEventsService } from '../services';
import { AppConfig } from '../app.config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountComponent } from '../account/account.component';
import { JobEditComponent } from '../job-edit/job-edit.component';
import { JobService } from '../services/job.service';
import { takeUntil, tap, distinctUntilChanged, catchError } from 'rxjs/operators';
import { JobPollingService } from '../services/JobsPollingService';
import { BusyModalComponent } from '../busy/busy.component';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobListComponent implements OnInit, OnDestroy {
  @ViewChild('snav', { static: true }) snav: MatSidenav;

  colors = {
    0: ['#992fc7', '#6b0f93'],
    1: ['#337ab7', '#337ab7'],
    2: ['#337ab7', '#337ab7'],
    3: ['#337ab7', '#337ab7']
  };
  titles = ['Pending', 'Assigned', 'Completed'];
  displayedColumns: string[] = ['name', 'processingDate', 'statusId'];
  activetab: number;
  lastSyncDate: Date;
  showActionButton = true;
  navigationTriggeredViaApp = false;
  backButtonTriggerCount = 0;

  destroyed = new Subject();
  pendingJobs$: Observable<JobModel[]>;
  assignedJobs$: Observable<JobModel[]>;
  completedJobs$: Observable<JobModel[]>;

  isLoading = false;
  busydialogRef: MatDialogRef<BusyModalComponent>;

  get version() {
    return this.appConfig.version();
  }

  get activeTabLabel() {
    if (this.activetab === 1) {
      return 'Pending';
    }
    if (this.activetab === 2) {
      return 'Assigned';
    }
    if (this.activetab === 3) {
      return 'Completed';
    }

    return '';
  }

  constructor(private appService: AppService,
    private jobService: JobService,
    private appEvents: AppEventsService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    private jobPollingService: JobPollingService) { }

  ngOnInit() {
    this.reloadPending();

    this.activetab = +this.route.snapshot.queryParams['activetab'] || 1;
    this.lastSyncDate = this.appService.lastSyncDate;

    this.pendingJobs$ = this.jobService.getJobs$(JobStatusType.Pending).pipe(
        takeUntil(this.destroyed),
        distinctUntilChanged(),
        tap((_) => this.isLoading = false)
      );
    this.assignedJobs$ = this.jobService.getJobs$(JobStatusType.Assigned, JobStatusType.WIP).pipe(
        takeUntil(this.destroyed),
        distinctUntilChanged(),
      );
    this.completedJobs$ = this.jobService.getJobs$(JobStatusType.Completed).pipe(
        takeUntil(this.destroyed),
        distinctUntilChanged(),
      );
  }

  ngOnDestroy() {
    this.destroyed.next();
    if (this.busydialogRef) {
      this.busydialogRef.close();
    }

  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.reloadPending();
    }
  }

  reloadPending() {
    this.busydialogRef = this.dialog.open(BusyModalComponent, { panelClass: 'clear-bg', disableClose: true });
    this.isLoading = true;
    this.jobService.reloadJobs().subscribe((jobs) => {
      this.isLoading = false;
      this.busydialogRef.close();
      this.jobService.repopulateJobs(jobs);
    }, ((error) => {
      console.error(error);
      this.isLoading = false;
      this.busydialogRef.close();
    }));
  }

  // canDeactivate() {
  //   if (!this.navigationTriggeredViaApp) {
  //     this.backButtonTriggerCount++;
  //   }
  //   if (this.backButtonTriggerCount > 0) {
  //     this.appEvents.emitExitAppTrigger(true);
  //     this.backButtonTriggerCount = 0;
  //   }

  //   const deactivate = this.navigationTriggeredViaApp === true ? of(true) : of(false);
  //   this.navigationTriggeredViaApp = false;

  //   return deactivate;
  // }

  changeActiveTab(tab) {
    this.navigationTriggeredViaApp = true;
    this.activetab = tab;
    this.snav.toggle();
  }

  syncApp() {
    this.navigationTriggeredViaApp = true;
    this.navigationService.goto(`jobs/initial`);
  }

  gotoJob(id) {
    this.navigationTriggeredViaApp = true;
    this.navigationService.goto(`jobs/${id}`);
  }

  goto(url: string) {
    this.navigationTriggeredViaApp = true;
    this.navigationService.goto(url);
  }

  gotoAdd() {

    const dialogRef = this.dialog.open(JobEditComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: {} as JobModel
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // TODO: call save job
    });
  }

  logout() {
    this.navigationTriggeredViaApp = true;
    this.authService.logout(false);
    this.navigationService.gotoLogin();
  }

  toggleAccount() {
    const dialogRef = this.dialog.open(AccountComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: {name: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  resetApp() {
    this.jobService.resetApp$().subscribe((_) => {
      setTimeout(() => {
        this.syncApp();
      }, 0);
    });
  }
}
