import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unsaved-data',
  templateUrl: './unsaved-data.component.html',
  styleUrls: ['./unsaved-data.component.css']
})
export class UnsavedDataComponent {
  constructor(
    public dialogRef: MatDialogRef<UnsavedDataComponent>) {
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
