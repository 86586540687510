<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="example-container">
    <div style="position:absolute; width:90vw; top:50px; max-width: 400px">
      <img src="assets/icons/icon-96x96.png" style="margin:auto; display:block;" />
      <span class="version-container">v{{version}}</span>
    </div>
    <mat-form-field style="margin-top: 150px">
      <input matInput placeholder="Username" name="username" [(ngModel)]="model.username" #username="ngModel" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Password" type="password" name="password" [(ngModel)]="model.password" #password="ngModel" required>
    </mat-form-field>

    <button mat-raised-button color="primary" class="action-button" [disabled]="loading">
        <!--mat-icon class="custom-icon">login</mat-icon-->Login
    </button>

    <button mat-button [disabled]="true" name="loginExpired">
      <span *ngIf="expired" class="alert alert-danger">Login Expired</span>
      <span *ngIf="error" class="alert alert-danger">Invalid Credentials</span>
    </button>

  </div>
</form>
