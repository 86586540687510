import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, map, tap } from 'rxjs/operators';
import { LocalStorageService, AppEventsService } from '../services';
import { LOGGEDIN_USER_STORAGE } from '../shared/constants';

@Injectable()
export class AuthenticationService {
  constructor(
    private appEventsService: AppEventsService,
    private http: HttpClient) { }

  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<any>('api/authtokens', { UserName: username, Password: password }, httpOptions)
      .pipe(
        tap((u) => this.updateCurrentUser(u)),
        take(1)
      );
  }

  removeCurrentUser() {
    LocalStorageService.removeItem(LOGGEDIN_USER_STORAGE);
  }

  logout(expired: boolean) {
    this.removeCurrentUser();
    this.appEventsService.UserLoggedOut(expired);
  }

  updateCurrentUser(user) {
    console.log(user);
    if (user && user.token) {
      const localUser = LocalStorageService.getItem(LOGGEDIN_USER_STORAGE);

      user.subscribedToNotifications = localUser ? localUser.subscribedToNotifications : false;
      LocalStorageService.setItem(LOGGEDIN_USER_STORAGE, user);
    }

  }
}
