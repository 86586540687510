import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { pushDummyState, popDummyState } from '../services/history-utility';

@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.scss']
})
export class NumberSpinnerComponent implements OnInit, OnDestroy {
  value: number;
  actionButtonText: string;

  constructor(
    public dialogRef: MatDialogRef<NumberSpinnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.value = data.value;
      this.actionButtonText = data.actionButtonText;
    }

  ngOnInit() {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy() {
    popDummyState();
  }

  increment() {
    this.value++;
  }

  decrement() {
    if (this.value > 1) {
      this.value--;
    }
  }

  save() {
    this.dialogRef.close(this.value);
  }

  cancel() {
    this.dialogRef.close();
  }

}
