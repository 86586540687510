import { Workbox } from 'workbox-window';

export const registerServiceWorker = (fileUrl: string) => {
  const wb = new Workbox(fileUrl);
  console.log(fileUrl);

  wb.addEventListener('activated', (event) => {
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      console.log('Service worker activated for the first time!');

      console.log('service worker activated');
      // If your service worker is configured to precache assets, those
      // assets should all be available now.
    }
  });

  // Register the service worker after event listeners have been added.
  wb.register();
};
