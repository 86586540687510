import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Inject,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';
import SignaturePad, { IPointGroup } from 'signature_pad';
import { IBasicPoint } from 'signature_pad/dist/types/point';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobService } from '../services/job.service';
import { JobModel } from '../models';
import { pushDummyState, popDummyState } from '../services/history-utility';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureComponent implements OnInit, OnDestroy {
  @Input() signature: string;
  @Output() signatureDrawn = new EventEmitter<string>();
  signatureEdited = false;

  @ViewChild('signaturepadCanvas')
  set signaturePadCanvas(v: ElementRef) {
    setTimeout(() => {
      this.signaturePad = new SignaturePad(v.nativeElement, {
        minWidth: 1,
        penColor: 'rgb(0, 0, 0)'
      });

      this.resizeCanvas(v.nativeElement);
      this.signaturePad.onEnd = this.onEnd.bind(this);

      if (this.job.customerSign) {
        this.signaturePad.fromDataURL(this.job.customerSign);
      }
    }, 0);
  }

  signaturePad: SignaturePad;

  constructor(
    private jobService: JobService,
    public dialogRef: MatDialogRef<SignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public job: JobModel) {}


  ngOnInit(): void {
    pushDummyState(this.dialogRef.id);
  }

  ngOnDestroy(): void {
    popDummyState();
  }

  resizeCanvas(canvas: any) {
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext(`2d`).scale(ratio, ratio);
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  clear(): void {
    this.signaturePad.clear();
    this.signatureEdited = true;
  }

  onEnd(): void {
    this.signatureEdited = true;
  }

  toPointsGroup(points: string) {
    const groupPoints = points.replace('*|', '').split('|').map((p) => {
      const coordinates = p.split(',');
      return {
        time: 0,
        x: +coordinates[0],
        y: +coordinates[1]
      } as IBasicPoint;
    });

    return [{
      color: 'rgb(0, 0, 0)',
      points: groupPoints
    } as IPointGroup];
  }

  // converts pointgroup to '*|x,y|x,y|.... similar to what the old website does'
  toCoordinates(pointGroups: IPointGroup[]) {
    if (pointGroups.length > 0) {
      let signaturePoints = pointGroups[0].points
        .map((p) => `${p.x},${p.y}`)
        .join('|');

      signaturePoints = `*|${signaturePoints}`;

      return signaturePoints;
    }
  }

  async close() {
    const signatureString = this.signaturePad.toDataURL();
    if (signatureString && this.signatureEdited) {
      this.signatureEdited = false;
      this.jobService.updateSignature(this.job.id, signatureString).subscribe((_v) => {
        this.dialogRef.close();
      });
    }

    this.signatureEdited = false;
    this.dialogRef.close();
  }
}
