import { Injectable } from '@angular/core';
import { EquipmentService, CustomerService, JobListService } from './api';
import { Subject } from 'rxjs';
import { AppSession } from './services/app-session';
import { StaticDataService } from './services/static-data.service';
import {
  CUSTOMER_EQUIPMENTS_TABLE_NAME,
  EQUIPMENTS_TABLE_NAME,
  CUSTOMERS_TABLE_NAME,
  PARTS_TABLE_NAME,
  EQUIPMENT_TYPES_TABLE_NAME,
  RESOLUTIONS_TABLE_NAME,
  DexieService
} from './services';
import { repopulateJobs } from './services/query-utilities';

@Injectable()
export class AppService {
  private _backgroundTask: Subject<string>;
  private _totalBackgroundTasks: number;

  get BackgroundTask() {
    return this._backgroundTask.asObservable();
  }

  get NumberOfBackgroundTasks() {
    return this._totalBackgroundTasks;
  }

  get lastSyncDate() {
    return this.appSession.lastSyncDate();
  }

  constructor(
    private equipmentsApi: EquipmentService,
    private customersApi: CustomerService,
    private jobsApi: JobListService,
    private staticDataService: StaticDataService,
    private appSession: AppSession,
    private db: DexieService
  ) {
    this._backgroundTask = new Subject();
  }

  init() {
    this.appSession.updateLastDataSyncDate();

    this._totalBackgroundTasks = 7;
    this.loadEquipmentTypes();
    this.loadEquipments();
    this.loadCustomers();
    this.loadParts();
    this.loadResolutions();
    this.loadCustomerEquipment();
    this.loadJobs();
  }

  loadEquipmentTypes() {
    this.emitBackgroundTaskEvent('Loading equipment types');
    this.equipmentsApi.getTypes()
      .subscribe((response) => {
        this.addToIndexedDb(EQUIPMENT_TYPES_TABLE_NAME, response, 'Loading equipment types complete');
      });
  }

  loadEquipments() {
    this.emitBackgroundTaskEvent('Loading equipments');
    this.equipmentsApi.get()
      .subscribe((response) => {
        this.addToIndexedDb(EQUIPMENTS_TABLE_NAME, response, 'Loading equipments complete');
      });
  }

  loadParts() {
    this.emitBackgroundTaskEvent('Loading parts');
    this.equipmentsApi.getParts()
      .subscribe((response) => {
        this.addToIndexedDb(PARTS_TABLE_NAME, response, 'Loading parts complete');
      });
  }

  loadCustomers() {
    this.emitBackgroundTaskEvent('Loading customers');
    this.customersApi.get()
      .subscribe((response) => {
        this.addToIndexedDb(CUSTOMERS_TABLE_NAME, response, 'Loading customers complete');
      });
  }

  loadResolutions() {
    this.emitBackgroundTaskEvent('Loading resolution templates');
    this.equipmentsApi.getResolutions()
      .subscribe((response) => {
        this.addToIndexedDb(RESOLUTIONS_TABLE_NAME, response, 'Loading resolution templates complete');
      });
  }

  loadCustomerEquipment() {
    this.emitBackgroundTaskEvent('Loading customer equipment');
    this.equipmentsApi.getCustomerEquipment()
      .subscribe((response) => {
        this.addToIndexedDb(CUSTOMER_EQUIPMENTS_TABLE_NAME, response, 'Loading customer equipment complete');
      });
  }

  loadJobs() {
    this.emitBackgroundTaskEvent('Loading jobs');
    this.jobsApi.get()
      .subscribe((jobs) => {
        repopulateJobs(this.db, jobs).then((_) => {
          this._backgroundTask.next('Loading jobs complete');
        });
      });
  }

  private emitBackgroundTaskEvent(task) {
    setTimeout(() => this._backgroundTask.next(task), 0);
  }

  private addToIndexedDb(tableName: string, data: unknown[], completeMessage: string) {
    this.staticDataService.addBulk(tableName, data).then((v) => {
      this._backgroundTask.next(completeMessage);
      console.log(v);
    });
  }
}
