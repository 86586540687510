import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnChanges {
  @Input() width: string;
  @Input() color: string;
  style = {
    'border': '0.25rem solid rgba(255, 255, 255, 0.2)',
    'border-top-color': 'white'
  };

  constructor() { }

  ngOnChanges(changes) {
    if (changes.width) {
      this.style.border = `${this.width} solid rgba(255, 255, 255, 0.2)`;
    }
    if (changes.color) {
      this.style['border-top-color'] = this.color;
    }
  }

}
