import { CustomerEquipmentModel } from './customer-equipment';
import { MeterReadingPhotoModel } from './meter-reading-photo';

export interface JobEquipmentModel {
  id: number;
  uid: string;
  jobId: number;
  customerEquipmentId: number;
  customerEquipmentUid: string;
  description: string;
  comment: string;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  customFieldNameId: number;

  customerEquipment: CustomerEquipmentModel;

  meterReadingPhotoId: number;
  meterReadingPhoto: MeterReadingPhotoModel;
}

export class JobEquipment implements JobEquipmentModel {
  customerEquipment: CustomerEquipmentModel;
  meterReadingPhoto: MeterReadingPhotoModel;

  constructor(
    public id: number,
    public uid: string,
    public jobId: number,
    public customerEquipmentId: number,
    public customerEquipmentUid: string,
    public description: string = null,
    public comment: string = null,
    public customField1: string = null,
    public customField2: string = null,
    public customField3: string = null,
    public customField4: string = null,
    public customField5: string = null,
    public meterReadingPhotoId: number = null,
    public customFieldNameId: number = null) {

    Object.defineProperties(this, {
      customerEquipment: { value: null, enumerable: false, writable: true },
      meterReadingPhoto: { value: null, enumerable: false, writable: true }
    });
  }

}
