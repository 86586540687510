import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

function _closeCurrentWindow(): any {
  window.close();
}

function _openUrlInCurrentWindow(url): any {
  window.open(url);
}

function _triggerClickOnElement(elementId): any {
  const element = document.getElementById(elementId);
  element.dispatchEvent(new Event('touchstart'));
  element.dispatchEvent(new Event('touchend'));
  element.click();

  event = new Event('touchstart');
  event.initEvent('touchstart', true, true);
}

function _goFullScreen() {
  const doc = window.document as any;
  const docEl = doc.documentElement as any;

  const requestFullScreen = docEl.requestFullscreen ||
    docEl['mozRequestFullScreen'] ||
    docEl.webkitRequestFullscreen ||
    docEl['msRequestFullscreen'];

  if (!doc.fullscreenElement && !doc['mozFullScreenElement'] && !doc.webkitFullscreenElement && !doc['msFullscreenElement']) {
    // alert('requesting full screen');
    requestFullScreen.call(docEl);
    // if (document.body.webkitRequestFullscreen) {
    //   // alert('going full mode...')
    //   document.body.webkitRequestFullscreen();
    // }
  }

  // window.scrollTo(0,1);
}

function _getOriginForSelf() {
  return( `${ window.location.protocol }//${ window.location.host }` );
}

function _postMessage(message) {
  window.postMessage(message, _getOriginForSelf());
}

@Injectable()
export class WindowRefService {
  get nativeWindow(): any {
    return _window();
  }

  closeCurrentWindow(): void {
    _closeCurrentWindow();
  }

  openUrlInCurrentWindow(url): void {
    _openUrlInCurrentWindow(url);
  }

  triggerClickOnElement(elementId): void {
    _triggerClickOnElement(elementId);
  }

  goFullScreen(): void {
    _goFullScreen();
  }

  send(message: any) {
    _postMessage(message);
  }
}
