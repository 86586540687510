import { Component, OnInit, Inject, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { JobEquipmentModel, JobModel, JobStatusType } from '../models';
import { NavigationService } from '../services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentAddComponent } from '../equipment-add/equipment-add.component';
import { JobService } from '../services/job.service';
import { Observable, Subject } from 'rxjs';
import { JOB_INFO, JOB_PROVIDERS } from '../services/job.provider';
import { withLatestFrom, map, takeUntil, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { JobViewModel } from '../models/job-viewmodel';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
  providers: [JOB_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentListComponent implements OnInit, OnDestroy {
  equipmentList$: Observable<JobEquipmentModel[]>;
  navigate = new Subject<number>();
  destroyed = new Subject();
  addEquipmentClick = new Subject();
  deleteEquipmentClick = new Subject<string>();
  newDataSent = new Subject<JobViewModel>();
  jobViewModel$: Observable<JobViewModel>;
  readonly$: Observable<boolean>;

  constructor(
    private navigationService: NavigationService,
    private jobService: JobService,
    private dialog: MatDialog,
    @Inject(JOB_INFO) readonly data$: Observable<JobViewModel>) {
      this.data$.pipe(takeUntil(this.destroyed)).subscribe((data) => this.newDataSent.next(data));
      this.jobViewModel$ = this.newDataSent.asObservable(); // do this so we do not recreate this.jobViewModel$ observable
      this.readonly$ = this.jobViewModel$.pipe(map((jv) => jv?.job?.statusId !== JobStatusType.WIP));
     }

  ngOnInit(): void {
    this.equipmentList$ = this.jobViewModel$.pipe(
      map((data) => data.job.jobEquipments.map((je) => cloneDeep(je) )),
      takeUntil(this.destroyed),
    );

    this.navigate.asObservable().pipe(
      withLatestFrom(this.jobViewModel$, (equipmentId, data) => ({ equipmentId, jobId: data.job.id })),
      takeUntil(this.destroyed),
    ).subscribe(({ jobId, equipmentId }) => {
      if (equipmentId) {
        this.navigationService.goto(`/jobs/${jobId}/equipments/${equipmentId}`);
      } else {
        this.navigationService.goto(`/jobs/${jobId}`);
      }
    });

    this.addEquipmentClick.asObservable().pipe(
      withLatestFrom(this.jobViewModel$, (_, data) => data.job),
      takeUntil(this.destroyed),
    ).subscribe((job) => {
      this.addEquipment(job);
    });

    this.deleteEquipmentClick.asObservable().pipe(
      switchMap((jobEquipmentUid) => {
        return this.jobService.getJobEquipmentByIdTake1$(jobEquipmentUid, false);
      }),
      takeUntil(this.destroyed),
    ).subscribe((jobEquipment) => {
      this.jobService.deleteJobEquipment(jobEquipment.jobId, jobEquipment.id, jobEquipment.uid).subscribe((v) => {
        console.log(`job equipment deleted ${v}`);
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  addEquipment(job: JobModel) {
    const dialogRef = this.dialog.open(EquipmentAddComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      panelClass: 'no-padding',
      data: job
    });

    dialogRef.afterClosed().subscribe((jobEquipment: JobEquipmentModel) => {
      if (jobEquipment) {
        this.jobService.addJobEquipment(jobEquipment).subscribe((v) => {
          console.log(v);
        });
      }
    });
  }

  gotoEquipment(equipmentId: number) {
    this.navigate.next(equipmentId);
  }

  onEquipmentDelete(jobEquipmentId: string) {
    this.deleteEquipmentClick.next(jobEquipmentId);
  }
}
