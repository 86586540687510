<app-shell
  icon="close"
  [breadCrumbs]="['Add Resolution']"
  (close)="close()">

  <div style="padding: 15px; border-bottom: solid 1px #ccc;">
    <button class="action-button" mat-raised-button color="primary" (click)="linkEquipment()">
      <mat-icon>link</mat-icon>Link To Equipment
    </button>

    <p style="text-align: center;">Job Equipment:
      <span *ngIf="jobEquipment">{{jobEquipment?.customerEquipment?.equipment?.name}}({{jobEquipment?.customerEquipment?.serialNumber}})</span>
      <span *ngIf="!jobEquipment">Not equipment related</span>
    </p>
  </div>

  <div style="padding: 15px; border-bottom: solid 1px #ccc;">
    <button class="action-button" mat-raised-button color="primary" (click)="selectTemplate()">
      <mat-icon>search</mat-icon>Search Template
    </button>
  </div>

  <div style="padding: 15px;">
    <mat-form-field class="full-width">
      <textarea matInput [formControl]="resolution"></textarea>
    </mat-form-field>
    <button class="action-button" mat-raised-button color="primary" (click)="addResolution()" [disabled]="resolution.valid === false">
      <mat-icon>note_add</mat-icon>Add Resolution
    </button>

    <ng-content></ng-content>
  </div>

</app-shell>

