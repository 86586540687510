import { Injectable } from '@angular/core';
import { from, Observable, Subject, forkJoin, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  JobModel,
  JobEquipmentModel,
  CommentModel,
  JobPartModel,
  JobStatusType,
  JobCompleteModel
} from '../models';
import { DexieService } from './dexie.service';
import { startWith, switchMap, map, tap, catchError, distinctUntilChanged } from 'rxjs/operators';
import { IDatabaseChange } from 'dexie-observable/api';
import {
  getJobs,
  getJobEquipments,
  loadJobEquipmentNavigationProperties,
  getJobPartById,
  getJob,
  repopulateJobs,
} from './query-utilities';
import { JobListService } from '../api';
import { DEVICE } from '../shared/constants';
import { AppEventsService } from './app-events.service';
import { CommentService } from '../api/comment.service';
import { cloneDeep } from 'lodash';
import { tapAlerts, tapDeleteAlerts } from '../operators/map.operators';
import { MeterReadingPhotoModel } from '../models/meter-reading-photo';

@Injectable()
export class JobService {
  private tableUpdated: Subject<boolean>;
  private jobRelatedTables = ['jobEquipments', 'jobParts', 'comments', 'jobs'];

  constructor(private db: DexieService,
    private commentsApi: CommentService,
    private jobsApi: JobListService,
    private appEvents: AppEventsService) {

    this.tableUpdated = new Subject();
    this.db.on('changes', (changes: IDatabaseChange[]) => {
      // console.log(changes);
      const isJobUpdated = changes.some((c) => this.jobRelatedTables.indexOf(c.table) > -1);
      if (isJobUpdated) {
        console.log('jobUpdated');
        this.tableUpdated.next(true);
      }
    });
  }

  resetApp$() {
    this.db.close();
    return from(this.db.delete().then(() => this.db.open()));
  }

  reloadJobs() {
    return this.jobsApi.get();
  }

  repopulateJobs(jobs) {
    repopulateJobs(this.db, jobs, true);
  }

  syncJobEquipments() {
    return from(this.db.jobEquipments.where('id').equals(0).toArray()).pipe(
      map((jobEquipments) => jobEquipments.map((j) => this.jobsApi.getJobEquipment(j.jobId, j.uid))),
      switchMap((jobRequests) => forkJoin(jobRequests) ),
      switchMap((jobEquipments) => Promise.all(jobEquipments.map((je) =>
          this.db.jobEquipments.update(je.uid, { id: je.id, customerEquipmentId: je.customerEquipmentId}))
        )
      )
    );
  }

  syncJobEquipmentParts() {
    return from(this.db.jobParts.where('id').equals(0).toArray()).pipe(
      map((jobParts) => jobParts.map((j) => this.jobsApi.getJobEquipmentPart(j.jobId, j.uid))),
      switchMap((jobRequests) => forkJoin(jobRequests) ),
      switchMap((jobParts) => Promise.all(jobParts.map((jp) =>
          this.db.jobParts.update(jp.uid, { id: jp.id, customerEquipmentId: jp.customerEquipmentId}))
        )
      )
    );
  }

  public getJobById$(id: number): Observable<JobModel> {
    return this.tableUpdated.asObservable().pipe(
      startWith(true),
      switchMap((_) => getJob(this.db, id) ),
      distinctUntilChanged()
    );
  }

  public getCommentsByJobId$(jobId: number): Observable<CommentModel[]> {
    return from( this.db.comments.where('jobId').equals(jobId).sortBy('id') );
  }

  public getCustomerIdForJob$(jobId: number): Observable<number> {
    return from(this.db.jobs.get(jobId)).pipe(map((j) => j.customerId));
  }

  public getJobEquipmentById$(jobEquipmentId: string, includeChildren = true): Observable<JobEquipmentModel> {
    const jePromise = (db: DexieService) => db.jobEquipments.get(jobEquipmentId);
    const jeObservable = from(jePromise(this.db) ).pipe(
      switchMap( (je: JobEquipmentModel) => from(loadJobEquipmentNavigationProperties(this.db, je)) ),
    );

    return this.tableUpdated.asObservable().pipe(
      startWith(true),
      switchMap((_) => jeObservable),
    );
  }

  public getJobEquipmentByIdTake1$(jobEquipmentId: string, includeChildren = true): Observable<JobEquipmentModel> {
    const jePromise = (db: DexieService) => db.jobEquipments.get(jobEquipmentId);
    let jeObservable = from(jePromise(this.db) );

    if (includeChildren) {
      jeObservable = jeObservable.pipe(
        switchMap( (je: JobEquipmentModel) => from(loadJobEquipmentNavigationProperties(this.db, je)) ),
      );
    }

    return jeObservable;
  }

  public getJobs$(...statusTypes: JobStatusType[]): Observable<JobModel[]> {
    return this.tableUpdated.asObservable().pipe(
      startWith(true),
      switchMap((_) => from( getJobs(this.db, statusTypes))),
    );
  }

  public getJobEquipments$(jobId: number): Observable<JobEquipmentModel[]> {
    const jobEquipments$ = (db: DexieService) => from(getJobEquipments(db, jobId));

    return this.tableUpdated.asObservable().pipe(
      startWith(true),
      switchMap((_) => jobEquipments$(this.db)),
    );
  }

  public getJobPartById$(jobPartId: string) {
    const jobPart$ = (db: DexieService) => from(getJobPartById(db, jobPartId));

    return jobPart$( this.db );
  }

  addJob(job: JobModel) {
    if (!job.uid) {
      job.uid = uuidv4();
    }

    let saveToServer$ = this.jobsApi.save(job);
    if (!job.id) {
      saveToServer$ = this.jobsApi.create(job);
    }

    saveToServer$.subscribe((v) => {
      if (v) {
        this.db.jobs.put(v as JobModel);
      }
    });
  }

  addComment(comment: string, technicianId: number, jobId: number, serialNumber: string = 'JOB RESOLUTION - NO ASSOCIATED EQUIPMENT') {
    const commentModel = {
      comment,
      technicianId: technicianId,
      serialNumber,
      jobId
    } as CommentModel;

    return this.commentsApi.create(commentModel).pipe(
      tapAlerts('Resolution', this.appEvents),
      switchMap((response) => response ? this.db.comments.put(response as CommentModel) : of(null))
    );
  }

  updateSignature(jobId: number, signature: string) {
    return this.jobsApi.updateSignature(jobId, signature).pipe(
      tapAlerts('Signature', this.appEvents),
      switchMap((_) => this.db.jobs.update(jobId, { customerSign: signature })
    ));
  }

  public addJobEquipment(jobEquipment: JobEquipmentModel) {
    return this.jobsApi.addJobEquipment(jobEquipment.jobId, jobEquipment).pipe(
        tapAlerts('Job Equipment', this.appEvents),
        switchMap((response) => {
          return from(this.db.customerEquipments.add(response.customerEquipment)).pipe(
            switchMap((uid) => {
              response.customerEquipmentUid = uid;
              delete response.customerEquipment;
              return this.db.jobEquipments.add(response);
            })
          );
        })
      );
  }

  public deleteJobEquipment(jobId: number, jobEquipmentId: number, jobEquipmentUid: string) {
    const deleteJobEquipment = jobEquipmentId ?
      this.jobsApi.deleteJobEquipment(jobId, jobEquipmentId) :
      this.jobsApi.deleteJobEquipmentByUid(jobId, jobEquipmentUid);

    return deleteJobEquipment.pipe(
        tapDeleteAlerts ('Job Equipment', this.appEvents),
        switchMap((_) => this.db.jobEquipments.delete(jobEquipmentUid))
      );
  }

  public addJobEquipmentPart(jobPart: JobPartModel) {
    jobPart = cloneDeep(jobPart);

    if (!jobPart.uid) {
      jobPart.uid = uuidv4();
    }

    return this.jobsApi.addJobEquipmentPart(jobPart.jobId, jobPart.customerEquipmentId, jobPart).pipe(
        tapAlerts('Job Equipment Part', this.appEvents),
        switchMap((response) => this.db.jobParts.add(response))
      );
  }

  public deleteJobEquipmentPart(jobId: number, customerEquipmentId: number, jobPartUid: string) {
    return from( this.db.jobParts.get(jobPartUid) ).pipe(
      switchMap((jobPart) => jobPart.id ?
        this.jobsApi.deleteJobEquipmentPart(jobId, jobPart.id) :
        this.jobsApi.deleteJobEquipmentPartByUid(jobId, jobPart.uid)),
      tapDeleteAlerts ('Job Equipment Part', this.appEvents),
      switchMap((_) => this.db.jobParts.delete(jobPartUid))
    );
  }

  public updateJobEquipmentPart(jobPart: JobPartModel) {
    return this.jobsApi.updateJobEquipmentPart(jobPart.jobId, jobPart.uid, jobPart).pipe(
      tapAlerts('Job Equipment Part', this.appEvents),
      switchMap((response) => this.db.jobParts.update(jobPart.uid, { quantity: response.quantity }))
    );
  }

  public getJobEquipmentMeterReading(meterReadingId: number): Observable<MeterReadingPhotoModel> {
    return this.jobsApi.getJobEquipmentMeterReading(meterReadingId).pipe(
      tapAlerts('Get meter reading', this.appEvents)
    );
  }

  public addJobEquipmentMeterReading(jobId: number, jobEquipmentId: number, meterReading: string) {
    const meterReadingModel = {
      data: meterReading,
      jobEquipmentId
     } as MeterReadingPhotoModel;
    return this.jobsApi.updateJobEquipmentMeterReading(jobId, jobEquipmentId,  meterReadingModel).pipe(
      tapAlerts('Added meterreading', this.appEvents),
      switchMap((response) => {
        return this.db.meterReadings.put(meterReadingModel);
      })
    );
  }

  async assign(jobId: number, technicianId: number) {
    const update = {
      statusId: JobStatusType.Assigned,
      technicianId: technicianId,
      // comment: `Job Assigned: ${comment}`
    } as JobModel;

    this.jobsApi.assign(jobId, DEVICE).pipe(
      map((response: any) => {
      this.handleGenericServerResponse(response, 'assign');
      return response.offline ? response.data : response;
    })).subscribe((v) => {
      console.log(`Job(${jobId}) assign success`);
      console.log(v);
    });

    return await this.db.jobs.update(jobId, update);
  }

  async start(jobId: number) {
    const update = {
      statusId: JobStatusType.WIP,
    } as JobModel;

    this.jobsApi.start(jobId, DEVICE).pipe(
      map((response: any) => {
      this.handleGenericServerResponse(response, 'start');
      return response.offline ? response.data : response;
    })).subscribe((v) => {
      console.log(`Job(${jobId}) start success`);
      console.log(v);
    });

    return await this.db.jobs.update(jobId, update);
  }

  async release(jobId: number, comment: string) {
    const update = {
      statusId: JobStatusType.Pending,
      technicianId: null,
      comment: `Job Released: ${comment}`
    } as JobModel;

    this.jobsApi.release(jobId, DEVICE, update.comment).pipe(
        map((response) => {
          this.handleGenericServerResponse(response, 'release');
          return response;
        })
      ).subscribe((v) => {
        console.log(`Job(${jobId}) release success`);
        console.log(v);
      });

    return await this.db.jobs.update(jobId, update);
  }

  async complete(jobComplete: JobCompleteModel) {
    const update = Object.assign({}, jobComplete, { statusId: JobStatusType.Completed });

    this.jobsApi.complete(update, DEVICE).pipe(
      map((response: any) => {
      this.handleGenericServerResponse(response, 'complete');
      // return response.offline ? response.data.id : response.id;
      return response;
    })).subscribe((v) => {
      console.log(`Job(${jobComplete.jobId}) complete success`);
      console.log(v);
    });

    return await this.db.jobs.update(jobComplete.jobId, update);
  }

  private handleGenericServerResponse(response: any, action: string) {
    if (response) {
      if (response.offline) {
        this.appEvents.emitAlert('Job queued to ' + action, 'Offline');
      } else {
        // TODO: handle response from api
        // this.update(response);
      }
    } else {
      this.appEvents.emitAlert('Job failed to ' + action, 'Error');
    }
  }
}
