export enum JobStatusType {
  Pending = 1,
  AllocatedJobsWaiting = 2,
  WIP = 3,
  Completed = 6,
  ServicingJobsWaiting = 8,
  Assigned = 9
}

export const JobStatusTypeNames = new Map<number, string>([
  [JobStatusType.Pending, 'Pending'],
  [JobStatusType.AllocatedJobsWaiting, 'Allocated Jobs Waiting'],
  [JobStatusType.WIP, 'WIP'],
  [JobStatusType.Completed, 'Completed'],
  [JobStatusType.ServicingJobsWaiting, 'Servicing Jobs Waiting'],
  [JobStatusType.Assigned, 'Assigned']
]);
